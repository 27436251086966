import { avatarAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { CLR_AVATAR_BG } from '../colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
	avatarAnatomy.keys,
);

const beVariant = definePartsStyle({
	container: {
		borderRadius: '50%',
		background: CLR_AVATAR_BG,
		color: 'white',
		lineHeight: 'unset',
		height: '41px',
		width: '41px',
	},
	excessLabel: {
		borderRadius: '50%',
		background: 'grey.800',
		border: '2px solid white',
	},
});

export const beAvatarTheme = defineMultiStyleConfig({ variants: { beVariant } });
