import {all, fork} from 'redux-saga/effects';
import dashboardSaga from './dashboard';
import userSaga from './user';
import sessionSaga from './session';

export default function* rootSaga() {
  yield all([fork(dashboardSaga)]);
  yield all([fork(userSaga)]);
  yield all([fork(sessionSaga)]);
}
