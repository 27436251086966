import { UrlConstant } from '../../Core/Interfaces/interfaces';
import { HttpMethod } from '../methods';

export const POST_NEW_ORGANIZATION: UrlConstant = {
	path: '/organizations',
	method: HttpMethod.POST,
};

export const GET_ORGANISATIONS: UrlConstant = {
	path: '/users/me/organizations',
	method: HttpMethod.GET,
};

export const GET_ORGANIZATION: UrlConstant = {
	path: '/organizations/:id',
	method: HttpMethod.GET,
};

export const POST_ORGANIZATION_INVITE: UrlConstant = {
	path: '/organizations/:id/members/invites',
	method: HttpMethod.POST,
};

export const DELETE_ORGANIZATION_MEMBER: UrlConstant = {
	path: '/organizations/:organizationId/members/:memberId',
	method: HttpMethod.DELETE,
};

export const PATCH_ORGANIZATION: UrlConstant = {
	path: '/organizations/:id',
	method: HttpMethod.PATCH,
};

export const POST_ORGANIZATION_DEFAULT_TEMPLATE: UrlConstant = {
	path: '/organizations/:id/set-default-primary-id',
	method: HttpMethod.POST,
};

export const POST_ORGANIZATION_TEMPLATE: UrlConstant = {
	path: '/organizations/:id/add-template',
	method: HttpMethod.POST,
};

export const DELETE_ORGANIZATION: UrlConstant = {
	path: '/organizations/:id',
	method: HttpMethod.DELETE,
};

export const GET_ORGANIZATION_INVITE: UrlConstant = {
	path: '/organizations/members/invites',
	method: HttpMethod.GET,
};

export const POST_ORGANIZATION_INVITE_ACCEPT: UrlConstant = {
	path: '/organizations/:id/members/invites/accept',
	method: HttpMethod.POST,
};

export const DELETE_ORGANIZATION_INVITE: UrlConstant = {
	path: '/organizations/:id/members/:userId',
	method: HttpMethod.DELETE,
};
