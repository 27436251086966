import {
  Text,
  Center,
  Flex,
  useColorMode,
} from "@chakra-ui/react";
import T from "../../../../../Core/Translations";
import { CLR_PANEL_BG, CLR_PANEL_BG_DARK } from "../../../../../../Lib/Theme/Light/colors";

export default function ConsentScreen() {

  const { colorMode } = useColorMode()

  return (
    <Center
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      mt={[0, 0, 20]}
    >
      <Flex
        w={["full", "full", "800px"]}
        h={["calc(100vh)", "calc(100vh)", "full"]}
        bgColor={colorMode === 'light' ? CLR_PANEL_BG : CLR_PANEL_BG_DARK}
        flexDirection="column"
        padding={[8, 8, 10]}
        borderRadius={8}
        borderWidth={[0, 0, 1]}
        overflow="auto"
      >
        <Text variant="beTextHeading" fontSize="24px" textAlign="center">
          {T.get("Install Boostlingo AI Pro")}
        </Text>
        <Text textAlign="center">
          {T.get("Once you add this app to a meeting you can start it by clicking on its icon in the tab bar.")}
        </Text>
      </Flex>
    </Center>
  );
}
