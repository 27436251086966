import React, { useEffect, useState } from "react";
import Cookies from 'cookies-js';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  Text,
  Button,
} from "@chakra-ui/react";
import config from "../../../../../Config";
import BonfireAPI from "../../../../../Core/Data/Http";
import GeneralModel from "../../../../../Models/General";
import { ACTIVATE_USER } from "../../../../../Models/Users/urls";

export default function ActivateUser() {
  
  const [message, setMessage] = useState('');
	const waitRedirectTimeout = 300; // time to wait to show the activated message to user

	useEffect(() => {
		activate();
	}, []);

	const onClose = () => {
		// TODO
	};

	const redirectToLogin = () => {
		setTimeout(() => {
			window.location.href = '/';
		}, waitRedirectTimeout);
	};
  
  const setCookieSameSite = (name: string, value: string, options: any) => {
    Cookies.set(name, value, options);
  
    // manually setting the cookie with SameSite=None and Secure so it can be seen when embedded inside an IFRAME
    if (value && config?.cookie?.domain) {
      document.cookie = [
        name + "=" + encodeURIComponent(value),
        "path=/",
        "domain=" + encodeURIComponent(config.cookie.domain),
        "SameSite=none",
        "Secure"
      ].join(";");
    }
  }

	const activate = async () => {
		const urlParams = new URLSearchParams(window?.location?.search);
		const email = urlParams.get('email');
		const code = urlParams.get('code');

		setMessage('Activating account, please wait ...');
		const errorMsg = "Can't activate account. Please try back later or contact support.";
		try {
      const response = await BonfireAPI.request(ACTIVATE_USER, GeneralModel, {
        client_id: config.api.client_id,
				email,
				code,
      });
      if (response instanceof GeneralModel) {
        const data = response.data;
        if (data.token) {
          // set login cookie if one is provided by the activation link
          setCookieSameSite(config.cookie.name, JSON.stringify(response.data.token), {
            domain: config.cookie.domain,
            secure: true,
            sameSite: 'none',
          });
        }
        if (data.alreadyActivated) {
          setMessage(
            "Account has been already activated. Redirecting to app. Please refresh page if it doesn't automatically reload.",
          );
          redirectToLogin();
        } else if (data.activateUser) {
          setMessage(
            "Account has been activated. Redirecting to app. Please refresh page if it doesn't automatically reload.",
          );
          redirectToLogin();
        } else {
          setMessage(data?.error || errorMsg);
        }
      } else {
        setMessage(response.message);
      }
		} catch (exc) {
			setMessage(String(exc) || errorMsg);
		}
	};

  return (
    <Modal isCentered isOpen={true} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader display="flex" alignItems="center">
					<Text variant="beTextHeading" fontSize="18px">
						Activate your account
					</Text>
				</ModalHeader>
				<ModalBody gap="23px" display="flex" flexDir="column">
					<Text>{message}</Text>
				</ModalBody>
			</ModalContent>
		</Modal>
  );
}
