import LanguagesModel from '../../../Models/Languages';
import TranscriptionMessageModel from "../../../Models/TranscriptionMessage";
import BonfireAPI from "../../../Core/Data/Http";
import {GET_SESSION} from "../../../Models/Sessions/urls";
import SessionsModel, {SessionEvent} from "../../../Models/Sessions";
import T from "../../../Core/Translations";

interface Message {
  message: string;
  id: string;
  language: string;
  participant: string;
  participantName: string;
  isRevised: boolean;
  messageParts?: any[];
}

class SessionJoinedHelper {

  static messageEndsWithPeriod(message: string) {
    return message?.endsWith(".") || message?.endsWith("?") || message?.endsWith("!");
  }

  static formatMessage(data: TranscriptionMessageModel): Message {

    var message: any = {
      message: data.message,
      id: data.id,
      language: data.langCode,
      participant: null,
      participantName: "",
      isRevised: false
    }

    if (data.participant) {

      if (Array.isArray(data.participant)) {
        if (data.participant.length > 0) {
          message.participant = data.participant[0];
        } else {
          message.participant = `${T.get(`Speaker`)}`
        }
      } else {
        message.participant = data.participant;
      }

      if (parseInt(message.participant)) {
         message.participantName = `${T.get(`Speaker`)} #${message.participant}`
      } else {
        message.participantName = message.participant;
      }
    } else {
      message.participant = `${T.get(`Speaker`)}`
      message.participantName = message.participant;
    }

    if (data.revision?.isRevised) {
      message.isRevised = true;
    }

    if (data.isFinal) {
      message.message = SessionJoinedHelper.formatExplain(message.message, data.explain);
    }

    return message;

  }

  static calculateMinutesDifferenceBetweenNowAndDate(date: Date) {
    const now = new Date();
    const difference = now.getTime() - date.getTime();
    return Math.floor(difference / 60000);
  }

  static calculateInitializationTime(session: SessionsModel): number {

    const events = session.events;

    var initializationEvent: SessionEvent | null = null;

    for (let i = 0; i < events.length; i++) {
      if (events[i].eventName === "initialized") {
        initializationEvent = events[i];
      }
    }

    if (!initializationEvent) {
      return 0;
    }

    const now = new Date();
    const eventTime = new Date(initializationEvent.timeStamp);
    const difference = now.getTime() - eventTime.getTime();
    const diff = Math.floor(difference / 1000);
    return diff;

  }

  static async getLatestSessionInfo(sessionId: string) {

    try {
      const session = await BonfireAPI.request(GET_SESSION, SessionsModel, { sessionId }) as SessionsModel;
      return session
    } catch (error) {
      console.log("Error getting session data for waiting interval", error);
    }

  }

  static waitForSessionReady(sessionId: string, callback: (status: any, doRetry: boolean) => void) {

    const waitingInterval = setInterval(async () => {

      try {
        const session = await BonfireAPI.request(GET_SESSION, SessionsModel, { sessionId }) as SessionsModel;
        if (session.status != 'initialized') {
          callback(session, false);
          clearInterval(waitingInterval);
        } else {
          const initSeconds = SessionJoinedHelper.calculateInitializationTime(session);
          if (initSeconds > 120) {
            callback(session, true);
          }
        }
      } catch (error) {
        console.log("Error getting session data for waiting interval", error);
      }

    }, 1500);

    return waitingInterval;

  }

  static formatExplain(text: string, explainData?: {explain: string, word: string}[]): string {

    var formattedText = text;

    if (!explainData) { 
      return formattedText;
    }

    explainData.forEach((data: {explain: string, word: string}) => {
      let regex = new RegExp("\\b" + data.word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + "\\b", "gi");
      formattedText = formattedText.replace(regex, (match) => {
        return `<explain title="${data.explain}">${match}</explain>`;
      });
    });

    return formattedText;

  }

  static formatMessages(messages: TranscriptionMessageModel[]) {
    const formattedMessages = messages.map((message: TranscriptionMessageModel) => SessionJoinedHelper.formatMessage(message));
    const finalMessages: Message[] = []

    formattedMessages.forEach((message: any, index: number) => {
      const lastIndex = finalMessages.length - 1;
      const lastMessageEndsWithPeriod = SessionJoinedHelper.messageEndsWithPeriod(finalMessages[lastIndex]?.message)
      const speakerIsDifferent = formattedMessages[index]?.participantName !== formattedMessages[index - 1]?.participantName;

      if (!lastMessageEndsWithPeriod && finalMessages.length > 0 && !speakerIsDifferent) {
        finalMessages[lastIndex].message += ` ${message.message}`;
      } else {
        finalMessages.push(message);
      }

    })

    return finalMessages;
  }

  static sortLanguages(languages: any[]) {
    languages.sort((a: any, b: any) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    // Filter if there are repeated values
    languages = Array.from(new Set(languages.map(item => item.key))).map(key => languages.find(item => item.key === key));

    return languages;

  }

  static findLanguageByCode(languagesList: LanguagesModel[], code: string): LanguagesModel {
    var language = languagesList.find(language => language.code === code) as LanguagesModel;
    if (!language) {
      code = code.split("-")[0];
      language = languagesList.find(language => language.code === code) as LanguagesModel;
    }
    return language;
  }
}

export default SessionJoinedHelper;
