import { chakraComponents, MultiValueRemoveProps, GroupBase } from "chakra-react-select";

const MultiValueRemove = ({ ...props } : MultiValueRemoveProps<{
  value: string;
  label: string;
}, true, GroupBase<{
  value: string;
  label: string;
}>>) => {
  props.innerProps.tabIndex = 0;
  props.innerProps.onKeyDown = (e) => {
    if (e.key == 'Enter') {
      const target = (e.target as any); // cast to any because EventTarget is not defined in full and we know we have click on this element
      target.click && target.click(); // simulate click
    }
  }
  //const X = chakraComponents;
  return <chakraComponents.MultiValueRemove {...props} />;
};

export default MultiValueRemove;