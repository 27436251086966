import UserModel from "../../../../Models/Users";
import {SET_MEETING_INSTANCE_OPTIONS} from "../Constants/session";
import SubscriptionsModel from "../../../../Models/Subscriptions";

export default function SessionReducer(
  state: {
    meetingInstanceOptions: {},
  } = {
    meetingInstanceOptions: {},
  },
  action: { type: string; payload: any }
) {
  if (action.type === SET_MEETING_INSTANCE_OPTIONS) {
    const newState = Object.assign({}, state);
    newState.meetingInstanceOptions = action.payload;
    return newState;
  }

  return state;
}
