import { Box, Link, Text } from "@chakra-ui/react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/alert";
import T from "../../../Core/Translations";
import Config from "../../../Config";
import { useSelector } from "react-redux";
import { RootState } from "../../../Core/Data/Store/Reducers";
import SubscriptionsModel from "../../../Models/Subscriptions";

const SubscriptionAlert = (props: any) => {
  const subscription = useSelector(
    (state: RootState) => state.user.subscription as SubscriptionsModel
  );

  const subscriptionUrl = `${Config.access.url}/subscriptions/overview`;

  const subscribePressed = () => {
    if (window.parent) {
      console.log("Sending message to parent", window.parent.location.href, {
        command: "openUrl",
        url: subscriptionUrl,
      });
      window.parent.postMessage(
        { command: "openUrl", url: subscriptionUrl },
        window.parent.location.href
      );
    }
  };

  if (!subscription) {
    return null;
  }

  if (subscription?.availableMinutes) {
      return null;
  }

  return (
    <Box mt={props.mt ? props.mt : 0} mb={props.mb ? props.mb : 0}>
      <Alert
        status="warning"
        variant="subtle"
        flexDirection={"column"}
        alignItems={"flex-start"}
        borderRadius="4px"
      >
        <Box flex={1} display={"flex"} flexDirection={"row"} mb={2}>
          <AlertIcon />
          <AlertTitle mr={1} fontWeight="bold">
            {T.get("Upgrade your subscription")}
          </AlertTitle>
        </Box>
        <AlertDescription>
          {T.get(
            "Unfortunately, your subscription is not currently active. To begin using the features of the Boostlingo AI Pro, kindly"
          )}{" "}
          <Link
            fontWeight={"bold"}
            textDecoration={"underline"}
            target={"_blank"}
            href={subscriptionUrl}
            onClick={() => subscribePressed()}
          >
            {T.get("click here to upgrade your subscription")}
          </Link>
        </AlertDescription>
      </Alert>
    </Box>
  );
};

export default SubscriptionAlert;
