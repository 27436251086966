import { useEffect, useState } from "react";
import BonfireAPI from "../Data/Http";
import {
  GET_ANNOTATION,
  POST_ANNOTATION,
  REVISE_MESSAGE,
} from "../../Models/Annotations/urls";
import AnnotationsModel from "../../Models/Annotations";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Data/Store/Reducers";
import {
  setAnnotatingMessage,
  setAnnotationsMessage,
  setAnnotationsReaction,
} from "../Data/Store/Actions/annotations";
import GeneralModel from "../../Models/General";

export default function useAnnotations(
  finalSentences: any,
  sessionId: string,
  hideAnnotations: boolean
) {
  const annotatingMessage = useSelector(
    (state: RootState) => state?.annotations?.annotatingMessage
  );

  const dispatch = useDispatch();
  const messageReaction = useSelector(
    (state: RootState) => state?.annotations?.reactions
  );
  const messageComment = useSelector(
    (state: RootState) => state?.annotations?.messages
  );

  useEffect(() => {
    if (hideAnnotations) return;
    getAnnotations();
  }, [hideAnnotations]);

  const getAnnotations = async () => {
    const annotations = await BonfireAPI.requestArray(
      GET_ANNOTATION,
      AnnotationsModel,
      {
        sessionId,
      }
    );

    if (annotations instanceof Error) return;
    const newMessageReaction = new Map(messageReaction);
    const newMessageComment = new Map(messageComment);

    annotations.forEach((annotation) => {
      newMessageReaction.set(annotation.messages[0], annotation.attachment);
      newMessageComment.set(annotation.messages[0], annotation.annotation);
    });

    dispatch(setAnnotationsReaction(newMessageReaction));
    dispatch(setAnnotationsMessage(newMessageComment));
  };

  const changeMessageReaction = async (reaction: string, messageId: string) => {
    let body = {
      sessionId,
      messages: [messageId],
      type: "reaction",
      attachment: [],
    };

    const currentReaction = messageReaction.get(messageId) || [];

    if (currentReaction.includes(reaction)) {
      const newReaction = currentReaction.filter((r: any) => r !== reaction);
      body.attachment = newReaction;
      dispatch(
        setAnnotationsReaction(
          new Map(messageReaction.set(messageId, newReaction))
        )
      );
      await BonfireAPI.post(POST_ANNOTATION.path, body);

      return;
    }

    currentReaction.push(reaction);

    dispatch(
      setAnnotationsReaction(
        new Map(messageReaction.set(messageId, currentReaction))
      )
    );
    body.attachment = currentReaction;

    await BonfireAPI.post(POST_ANNOTATION.path, body);
  };

  const changeMessageComment = async (text: any) => {
    dispatch(
      setAnnotationsMessage(
        new Map(messageComment.set(annotatingMessage.id, text))
      )
    );
    let body = {
      sessionId,
      messages: [annotatingMessage.id],
      annotation: text,
      type: "reaction",
    };
    await BonfireAPI.post(POST_ANNOTATION.path, body);
  };

  const startAnnotating = (messageId: string) => {
    if (!!annotatingMessage) {
      dispatch(setAnnotatingMessage(""));
    }

    const messageToWrite = finalSentences.find(
      (sentence: any) => sentence.id === messageId
    );
    dispatch(setAnnotatingMessage(messageToWrite));
  };

  const stopAnnotatingMessage = () => {
    dispatch(setAnnotatingMessage(""));
  };

  const reviseMessage = async (sentenceId: string) => {
    await BonfireAPI.request(REVISE_MESSAGE, GeneralModel, {
      sessionId,
      messageIds: [sentenceId],
    });
  };

  return {
    startAnnotating,
    changeMessageComment,
    changeMessageReaction,
    stopAnnotatingMessage,
    annotatingMessage,
    messageComment,
    messageReaction,
    reviseMessage,
  };
}
