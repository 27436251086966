/*
    Generic color names by purpose.
    Make names meaningful so even a color change or restyling will make them sound self-explanatory in the context they're used.
*/
export const CLR_PANEL_BG = '#f0f3f6';
export const CLR_PANEL_BG_DARK = '#1f1f1f';
export const CLR_PRIMARY_BG = '#051E4D';
export const CLR_MUTED_PRIMARY = '#C7E8FE';
export const CLR_SECONDARY_BG = '#1462F0';
export const CLR_SUCCESS_BG = "#5cb85c";
export const CLR_WARNING_BG = "#faa632";
export const CLR_DANGER_BG = "#DD3900";
export const CLR_TEXT_DESCRIPTION = "#252224";
export const CLR_AVATAR_BG = '#e46133';

export const CLR_AUDIO_INDICATOR = '#b4dcd9';

export const PARTICIPANTS_COLORS = [
    "#BBEAD9",
    "#BBD3EA",
    "#EABBD9",
    "#D9BBEA",
    "#EADCBF",
    "#EACBBB",
];
