import ModelAbstract from "../ModelAbstract";
import UserModel from "../Users";

export default class OrganizationModel extends ModelAbstract {
  id: string;
  name: string;
  eventDefaultPrimaryId: string;
  owners: any[];
  members: any[];
  invites: Array<{ user: any; owner: boolean }>;
  logo: string;
  subscription: any;
  seatsFilled: UserModel[];
  organizationType: any;

  constructor(data: any) {
    super();
    this.id = data.id;
    this.name = data.name;
    this.eventDefaultPrimaryId = data.eventDefaultPrimaryId;
    this.owners = data.owners;
    this.members = data.members;
    this.invites = data.invites;
    this.logo = data.logo;
    this.subscription = data.subscription;
    this.seatsFilled = data.seatsFilled;
    this.organizationType = data.organizationType;

    this.mapData();
  }

  validate(data: any): string {
    return "";
  }

  mapData(): void {}

  isOwner(userId: string) {
    const isUserOwner = this.owners.some((owner) => owner.id === userId);

    return isUserOwner;
  }

  static handleError(error: Error): void {
    console.log(error);
  }
}
