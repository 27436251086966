// @ts-nocheck
import {
  Box,
  Button,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Flex,
  Checkbox,
  Spinner,
  Avatar,
} from "@chakra-ui/react";
import T from "../../../Core/Translations";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useSessionCreated, {
  CreateData,
} from "../../../Core/Hooks/useSessionCreate";
import useBrowserExtension from "../../../Core/Hooks/useBrowserExtension";
import FormWrapper from "../../../Components/FormWrapper/Web";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import SubscriptionAlert from "../../../Components/SubscriptionAlert/Web";
import {
  requestMeetingInstanceCaptions,
  allowMeetingInstanceCaptions,
} from "../../../Core/Data/Store/Actions/session";
import SessionFormInfo from "../../../Components/SessionFormInfo/Web";

import { faLongArrowLeft } from "@fortawesome/free-solid-svg-icons";

const ParticipantWaitCaptionsAllowed = ({ state, meetingInstanceOptions }) => {
  const dispatch = useDispatch();

  const requestCaptions = () => {
    dispatch(
      requestMeetingInstanceCaptions(
        state.options.type,
        state.options.clientId,
        state.options.meetingKey
      )
    );
  };

  return (
    <Box mt={5}>
      <Text variant="beTextDescription" textAlign="center">
        {T.get(
          "The host of this Zoom meeting need to give permission to allow users to create captioning sessions using this app"
        )}
      </Text>
      {!!meetingInstanceOptions?.captionsRequested && (
        <Alert borderRadius="4px" mt="12px">
          <AlertIcon />
          <Text variant="beTextDescription">
            {T.get(
              "Captions have been requested. Waiting for host to approve."
            )}
          </Text>
        </Alert>
      )}
      <Box mt={5}>
        <Button
          variant="beSecondary"
          w="full"
          onClick={() => requestCaptions()}
        >
          {T.get("Request Permission")}
        </Button>
      </Box>
    </Box>
  );
};

const SessionCreateInformation = ({
  state,
  onSessionCreate,
  meetingInstanceOptions,
  error,
  loadingCreateSession,
}) => {
  if (
    state?.options &&
    !state?.options?.noApprovalNeeded &&
    !state?.options?.canApprove &&
    !meetingInstanceOptions?.captionsAllowed
  ) {
    return (
      <ParticipantWaitCaptionsAllowed
        state={state}
        meetingInstanceOptions={meetingInstanceOptions}
      />
    );
  }

  return (
    <>
      <Box mt={5}>
        {error && (
          <Alert variant="subtle" status="error" mb={5} borderRadius="4px">
            <AlertIcon />
            <AlertTitle mr={1} fontWeight="bold">
              {T.get("Error")}
            </AlertTitle>
            <AlertDescription>{T.get(error)}</AlertDescription>
          </Alert>
        )}
        <SessionFormInfo
          submitButtonText="Create"
          submitButtonTextRedirect="Create & Go to"
          handleSubmit={onSessionCreate}
          loadingSubmit={loadingCreateSession}
        />  
      </Box>
    </>
  );
};

const AdminWaitCaptionsAllowed = ({ state, meetingInstanceOptions }) => {
  const captionsAllowed = !!meetingInstanceOptions?.captionsAllowed;

  const dispatch = useDispatch();

  const allowCaptions = (captionsAllowed: boolean) => {
    dispatch(
      allowMeetingInstanceCaptions(
        state.options.type,
        state.options.clientId,
        state.options.meetingKey,
        captionsAllowed
      )
    );
  };

  return (
    <Box>
      {!!meetingInstanceOptions?.captionsRequested && (
        <Alert borderRadius="4px">
          <AlertIcon />
          <Text variant="beTextDescription">
            {T.get("Captions have been requested.")}
          </Text>
        </Alert>
      )}
      <Flex mt={3} alignItems="center" gap="12px" justifyContent="right">
        <Text variant="beTextDescription" color="black">
          {T.get("Allow other users to start a session in this meeting")}
        </Text>
        <Checkbox
          bg="white"
          size="lg"
          isChecked={captionsAllowed}
          onChange={(e) => allowCaptions(e.target.checked)}
        />
      </Flex>
    </Box>
  );
};

const CreateSession = () => {
  const { state } = useLocation();

  const [context, setContext] = useState<any>();
  const navigate = useNavigate();

  const { inBrowserExtension } = useBrowserExtension();

  const sessionJoined = (data: any, type: string, subType?: string) => {
    navigate(`/session-joined/${data.raw.session_id}`, {
      state: {
        session: data,
        initialUrl: state?.options?.initialUrl,
        type: type,
        subType,
        isLinkedToEvent: state?.isLinkedToEvent,
      },
    });
  };

  const {
    createSessionWithProvider,
    createWithProvider,
    error,
    meetingInstanceOptions,
    createSessionWithLocal,
    isLoading,
  } = useSessionCreated(state, sessionJoined);

  const searchParams = new URLSearchParams(window.location.search);

  const isAccess = searchParams.get("isAccess") === "true";

  useEffect(() => {
    function onMessage(event) {
      const message = event.data;
      if (message.type == "get-tab-context") {
        setContext(message.context);
        //if (message.context?.title) setSessionName(message.context.title);
      }
    }
    window.addEventListener("message", onMessage);

    window.parent.postMessage({ type: "get-tab-context" }, "*");

    return () => window.removeEventListener("message", onMessage);
  }, []);

  const onSessionCreate = (createData: CreateData, isRedirect: boolean) => {
    if (createWithProvider) {
      return createSessionWithProvider(createData, true, isRedirect);
    } else {
      return createSessionWithLocal(createData, true, isRedirect);
    }
  };

  if (searchParams.get("type") === "quick-local") {
    return (
      <Flex alignItems="center" w="100vw" justifyContent="center" h="100vh">
        <Spinner />
      </Flex>
    );
  }

  return (
    <FormWrapper
      isProvider={createWithProvider}
      displayGoBack={
        !inBrowserExtension
      }
    >
      <Box>        
        {createWithProvider && state?.options?.canApprove && (
          <AdminWaitCaptionsAllowed
            meetingInstanceOptions={meetingInstanceOptions}
            state={state}
          />
        )}
        {(!state?.options || state?.options?.canApprove) && (
        <Flex alignItems="start" justifyContent="space-between">
          <Box>
            <Text flex={1} variant="beTextHeading">
              {T.get("Create Session")}
            </Text>
          </Box>          
        </Flex>
        )}
      </Box>
      <SubscriptionAlert mb={0} mt={5} />
      <SessionCreateInformation
        state={state}
        loadingCreateSession={isLoading}
        error={error}
        onSessionCreate={onSessionCreate}
        meetingInstanceOptions={meetingInstanceOptions}
      />
    </FormWrapper>
  );
};

export default CreateSession;
