import {
  takeLatest,
  put,
  call,
  CallEffect,
  PutEffect,
} from "redux-saga/effects";
import {
  setUser,
  setUserSubscription,
  setUserOrganizations,
} from "../Actions/user";
import BonfireAPI from "../../Http";
import UserModel from "../../../../Models/Users";
import { GET_USER } from "../../../../Models/Users/urls";
import { GET_USERS_SUBSCRIPTION } from "../../../../Models/Subscriptions/urls";
import {
  GET_ACTIVE_USER,
  GET_USER_SUBSCRIPTION,
  GET_USER_ORGANIZATIONS,
} from "../Constants/user";
import { GET_ORGANISATIONS } from "../../../../Models/OrganizationModel/urls";
import SubscriptionsModel from "../../../../Models/Subscriptions";
import OrganizationModel from "../../../../Models/OrganizationModel";
import { datadogRum } from "@datadog/browser-rum";

function* fetchUser(): Generator<
  CallEffect<UserModel | Error> | PutEffect<{ type: string; payload: any }>,
  void,
  UserModel
> {
  try {
    const user = yield call(async () => {
      return await BonfireAPI.request(GET_USER, UserModel);
    });

    yield put(setUser(user));
  } catch (error) {
    console.log("Error getting user", error);
  }
}

function* fetchSubscription(): Generator<
  | CallEffect<SubscriptionsModel | Error>
  | PutEffect<{ type: string; payload: any }>,
  void,
  SubscriptionsModel
> {
  try {
    const subscription = yield call(async () => {
      return await BonfireAPI.request(
        GET_USERS_SUBSCRIPTION,
        SubscriptionsModel
      );
    });

    datadogRum.setUserProperty("freeTrial", subscription.freeTrial);

    yield put(setUserSubscription(subscription));
  } catch (error) {
    console.log("Error getting user subscription", error);
  }
}

function* fetchUserOrganizations(): Generator<
  | CallEffect<OrganizationModel[] | Error>
  | PutEffect<{ type: string; payload: any }>,
  void,
  OrganizationModel[]
> {
  try {
    const organizations = yield call(async () => {
      return await BonfireAPI.requestArray(
        GET_ORGANISATIONS,
        OrganizationModel
      );
    });
    yield put(setUserOrganizations(organizations));
  } catch (error) {
    console.log("Error getting user organizations", error);
  }
}
function* userSaga() {
  yield takeLatest(GET_ACTIVE_USER, fetchUser);
  yield takeLatest(GET_USER_SUBSCRIPTION, fetchSubscription);
  yield takeLatest(GET_USER_ORGANIZATIONS, fetchUserOrganizations);
}

export default userSaga;
