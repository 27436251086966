import { alertAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
	alertAnatomy.keys,
);

const beWarning = definePartsStyle({
	container: {
		background: '#FEEFB8',
		border: '1px solid #FAC710',
		borderRadius: '4px',
		wordBreak: 'break-word',
	},
	icon: {
		color: '#FAC710',
	},
});

export const alertTheme = defineMultiStyleConfig({
	variants: { beWarning },
});
