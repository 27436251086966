export {
    getMediaDevices
};

// options.type: undefined or "audioinput" (microphone), "audioouput" (speakers), "videoinput" (camera)
async function getMediaDevices(options: any) {
    options = options || {};
    const kind = options.kind;
    
    await navigator.mediaDevices.getUserMedia({ audio: true });
    return navigator.mediaDevices
        .enumerateDevices()
        .then((deviceInfos: any[]) => {
            deviceInfos = deviceInfos.filter((deviceInfo: any) => (!kind || deviceInfo.kind == kind) && deviceInfo.deviceId);
            return deviceInfos;
        });
}