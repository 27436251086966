import React from "react";
import {
  IconButton,
  useDisclosure,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  Text,
  Box,
  Tooltip,
  ModalCloseButton,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import AddNewLanguage from "../../AddNewLanguage/Web";
import MicrophoneInfo from "../../MicrophoneInfo/Web";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../Core/Data/Store/Reducers";
import { toggleSessionInformationDialog } from "../../../../../Core/Data/Store/Actions/general";
import useBrowserExtension from "../../../../../Core/Hooks/useBrowserExtension";

export default function SessionInformation({
  initialLanguages,
  languages,
  sessionData,
}: {
  initialLanguages?: { value: string; label: string }[];
  languages: any;
  languageChanged: (language: string) => void;
  currentLanguage: string;
  isOwner: boolean;
  sessionData: any;
}) {

  const dispatch = useDispatch();

  const { isSessionInformationOpened } = useSelector((state: RootState) => state.general);

  const { inBrowserExtension } = useBrowserExtension();

  const spokenLang = languages.filter((lang: any) => lang.isSpeakingLanguage);

  const { session } = sessionData;

  const isExternalApp = session?.eventType === "zoom" || session?.eventType === "teams";

  const showMicInfo = () => {
    return !(inBrowserExtension || isExternalApp);
  };

  return (
    <>
      <Tooltip label="Session Information">
        <IconButton
          aria-label="Session Information"
          variant="beOutline"
          size="sm"
          onClick={() => { dispatch(toggleSessionInformationDialog(true)) }}
          borderRadius="50%"
          icon={<FontAwesomeIcon icon={faInfo} size={"sm"} />}
        />
      </Tooltip>
      <Modal
        variant="beVariant"
        size="xl"
        trapFocus={false}
        isOpen={isSessionInformationOpened}
        onClose={() => {
          dispatch(toggleSessionInformationDialog(false));
        }}
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            Session Information
          </ModalHeader>
          <ModalBody gap="13px" display="flex" flexDir="column">
            <AddNewLanguage initialLanguages={initialLanguages} />
            <Box>
              <Text variant="beTextDescription">Spoken language</Text>
              <Text fontWeight={400} textTransform="capitalize">
                {spokenLang.map((lang: any) => lang.name).join(", ")}
              </Text>
            </Box>
            <Box>
              <Text variant="beTextDescription">Session type</Text>
              <Text fontWeight={400} textTransform="capitalize">
                {session.type}
              </Text>
            </Box>
            <Box>
              <Text variant="beTextDescription">Session name</Text>
              <Text fontWeight={400} textTransform="capitalize">
                {session.name}
              </Text>
            </Box>
            <Box>
              <Text variant="beTextDescription">Session id</Text>
              <Text fontWeight={400} textTransform="capitalize">
                {session.shortSessionId}
              </Text>
            </Box>
            {sessionData.dictionaryName && (
              <Box>
                <Text variant="beTextDescription">Glossaries used</Text>
                <Text fontWeight={400} textTransform="capitalize">
                  {sessionData.dictionaryName}
                </Text>
              </Box>
            )}
            {showMicInfo() && <MicrophoneInfo showSelect={true} />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
