import {
  Box,
  Button,
  Text,
} from "@chakra-ui/react";
import LanguagesDropdown from "../../../../../Components/LanguagesDropdown/Web";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import GeneralModel from "../../../../../Models/General";
import { SET_TRANSLATION_LANGUAGES } from "../../../../../Models/Languages/urls";
import BonfireAPI from "../../../../../Core/Data/Http";

const AddNewLanguage = (props: { initialLanguages?: { value: string; label: string }[] }) => {
  const { sessionId } = useParams();
  const [selectedLanguages, setSelectedLanguages] = useState<
    { value: string; label: string }[]
  >(props.initialLanguages || []);
  const [isLoading, setIsLoading] = useState(false);

  const saveLanguages = async (languages: { value: string; label: string }[]) => {
    setIsLoading(true);

    try {
      await BonfireAPI.request(SET_TRANSLATION_LANGUAGES, GeneralModel, {
        languages: languages.map((lang) => lang.value),
        sessionId,
      });
    } catch (error) {
      console.log("There was error", error);
    }

    setIsLoading(false);
  };

  const onChangeSelectedLanguages = (
    languages: { label: string; value: string }[]
  ) => {
    setSelectedLanguages(languages);
  };

  const didLanguageChanged = () => {
    const initialLanguages = props.initialLanguages || [];
    const selectedLanguagesList = selectedLanguages || [];

    var didFound = 0;

    for (var key in initialLanguages) {
      for (var key2 in selectedLanguagesList) {
        if (initialLanguages[key].value === selectedLanguagesList[key2].value) {
          didFound += 1;
          break;
        }
      }      
    }

    return didFound === initialLanguages.length && initialLanguages.length === selectedLanguagesList.length;
  }

  return (
    <>
      <Box>
        <Text variant="beTextDescription">Output Languages</Text>
        <LanguagesDropdown
          mt={1}
          title={""}
          initialLanguages={props.initialLanguages}
          onChange={onChangeSelectedLanguages}
          onLoaded={() => {}}
        />
        <Button
          variant="bePrimary"
          w="full"
          mt="12px"
          _hover={{ _disabled: { opacity: 0.4 } }}
          size="sm"
          isDisabled={
            isLoading ||
            didLanguageChanged()           
          }
          onClick={() => saveLanguages(selectedLanguages)}
        >
          Save Languages
        </Button>
      </Box>
    </>
  );
};

export default AddNewLanguage;
