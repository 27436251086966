import { Box, Alert, AlertIcon, AlertTitle, Flex } from "@chakra-ui/react";
import T from "../../../../../Core/Translations";

const StatusBar = ({ session, statusChangeReason }: { session: any, statusChangeReason: string }) => {
  const sessionOwner = session.isOwner;

  let statusText: string = '';

  const sessionStatus = session.session.status;
  const started = session.session.events.some((e: any) => e.eventName == "started");

  switch (sessionStatus) {
    case "paused":
      if (sessionOwner) {
        statusText = "Session is paused.";
        break;
      }
      statusText = "Session is paused. Please wait for the host to resume.";
      break;
    case "waiting":
      if (sessionOwner) {
        statusText = started ? "Session paused due to inactivity." : "When you are ready, please start the session.";
        break;
      }
      statusText = "Session is waiting for the host to start.";
      break;
    case "initialized":
      statusText = "Session is initializing. Please wait...";
      break;
  }

  if (!statusText || sessionStatus === "stopped") {
    return null;
  }

  const isReasonNotExist = () => {
    return !statusChangeReason || !session?.isOwner;
  }

  const renderStatusChangeReason = () => {
    if (isReasonNotExist()) {
      return T.get(statusText);
    }

    switch (statusChangeReason) {
      case "relayInitializeFailed":
        return T.get("Failed to initialize relay. Please try again.");
      case "enterNotAdmitted":
        return T.get("To receive captions, please allow the captions bot to join the meeting.");
      case "localRecordingNotAllowed":
        return T.get("Local recording is not enabled. Please enable it in your Zoom account settings and press Start. You may need to restart meeting after changing your settings.");
      case "localRecordingTimeout":
        return T.get("Local recording was not allowed by the host.")
      case "userIsNotOrganizer":
        return T.get("You are not the host of this meeting. Please ask the host to start the session.");
      case "workerIsDown":
        return T.get("There was a problem with captioning service. Please press start button to continue captioning.");
      default:
        return T.get(statusText);
    }

  }

  return (
    <Box mt="12px">
      <Alert status={isReasonNotExist() ? "warning" : "error"} variant="subtle" p="10px" pl="23px" pr="23px">
        <AlertIcon />
        <AlertTitle fontWeight="bold">
          {renderStatusChangeReason()}
        </AlertTitle>
      </Alert>
    </Box>
  );
};

export default StatusBar;
