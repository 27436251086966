import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys);

const variants = {
	beVariant: {
		button: {
			border: '1px solid #DDDDDD',
			borderRadius: '4px',
			padding: '7px',
			paddingRight: '5px',
			paddingLeft: '5px',
			background: '#EBF7FA',
			textAlign: 'left',
		},
	},
};

export const menuTheme = defineMultiStyleConfig({ variants });
