// @ts-nocheck
import ModelAbstract from '../ModelAbstract';

export default class SessionStatusModel extends ModelAbstract {
	isOwner: boolean;
	sessionContext: any;
	raw: any;

	constructor(data: any) {
		super();
		this.isOwner = data.isOwner;
		this.sessionContext = data.token ? data.token.context : {};
		this.raw = data.token;

		this.mapData();
	}

	mapData(): void {

	}

	validate(data: any): string {

		if (data.status === "success") {
			return "";
		}

		if (data.error) {
			return data.error;
		}

		return "Unexpected error";

	}

	static handleError(error: Error): void {
		console.log(error);
	}
}
