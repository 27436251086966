import { useEffect, useState } from "react";
import LanguagesDropdownViewModel from "../Core/viewModel";
import BonfireAPI from "../../../Core/Data/Http";
import {
  GET_LANGUAGES,
  GET_SPEAKING_LANGUAGES,
} from "../../../Models/Languages/urls";
import LanguagesModel from "../../../Models/Languages";

export default function useSpeakingLanguagesDropdown(props: {
  initialLanguages?: { value: string; label: string }[];
  initialLangCodes?: string[];
  onChange: (languages: { label: string; value: string }[]) => void;
  onLoaded: () => void;
  isMobile?: boolean;
}) {
  const [languagesSelected, setLanguagesSelected] = useState<any[]>([]);
  const [languagesList, setLanguagesList] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    loadLanguagesList();
  }, []);

  useEffect(() => {
    if (props.isMobile) {
      props.onChange(languagesSelected);
    }
  }, [languagesSelected]);

  const onChangeSelectedLanguages = (e: any) => {
    /*if (!props.isMobile) {
      languages = LanguagesDropdownViewModel.mapSelectedLanguages(
        languagesList,
        e
      );
      props.onChange(languages);
    }*/
    props.onChange(e);

    setLanguagesSelected(e);
  };

  const loadLanguagesList = async () => {
    try {
      const data = await BonfireAPI.requestArray(
        GET_SPEAKING_LANGUAGES,
        LanguagesModel
      );
      const languagesOptions = (data as LanguagesModel[]).map((language) => {
        return { value: language.code, label: language.name };
      });

      if (props.initialLangCodes) {
        const foundLangsFromCodes = languagesOptions
          .filter((lang: any) => props.initialLangCodes?.includes(lang.value))
          .map((lang: any) => lang.value);

        setLanguagesSelected(foundLangsFromCodes);
      }

      setLanguagesList(languagesOptions as any);
      props.onLoaded();
    } catch (error) {
      throw error;
    }
  };

  return {
    languagesList,
    languagesSelected,
    onChangeSelectedLanguages,
  };
}
