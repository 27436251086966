// @ts-nocheck
class SessionJoinedHelpers {
    
    private static deviceId: string;
    private static type: string;
    private static listenerData: any;

    private static lastAudioTime = 0;
    
    static isActive() {
        return !!this.type;
    }

    static getSilenceTime() {
        if (!this.type) return 0; // no mic capture is running
        if (!this.lastAudioTime) return 0;
        return (Date.now() - this.lastAudioTime) / 1000; // return silence time in seconds
    }
    
    static stopAudioToSocket() {
        switch (this.type) {
            case "stream": this.stopStreamingToSocket(); break;
            case "forward": this.stopForwardingToSocket(); break;
        }
    }
    
    static stopPublisher() {
        if (!this.publisher) return;
        this.publisher.disconnect();
        this.publisher = null;
    }

    static changeMicDeviceId(deviceId, cb) {
        // depending on the current case, this is doing stop/start of streamToSocket or forwardingToSocket
        if (this.deviceId == deviceId) return; // no change of device do not stop/start at all
        if (this.type == "stream") {
            this.stopMediaToSocket();
            this.startStreamingToSocket(deviceId, null, cb);
        } else if (this.type == "forward") {
            // TODO: must send message to Chrome Extension to change captured microphone device
            cb();
        } else {
            cb();
        }
    }

    static stopStreamingToSocket() {

        this.stopPublisher();
        this.stopMediaToSocket();
        
        this.type = "";
        this.listenerData = null;
        this.deviceId = null;

    }

    private static stopMediaToSocket() {
        const streamListener = this.listenerData;

        if (!streamListener?.stream) {
            return;
        }

        const audioTracks = streamListener.stream.getAudioTracks();
        audioTracks.forEach(track => track.stop());

        streamListener.destination.disconnect();
        streamListener.source.disconnect();
        streamListener.processor.disconnect();

    }

    static gatherSoundFromMic(returnEveryMs = 3000, callback = (chunks: any) => {}) {

        var audioChunks = [];
        var processor, source, destination;

        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(async (stream) => {

                const audioContext = new (window.AudioContext || window.webkitAudioContext)();

                const sampleRate = audioContext.sampleRate;
                const bufferSize = 4096; // Adjust buffer size as per your requirements


                processor = audioContext.createScriptProcessor(bufferSize, 1, 1);
                processor.onaudioprocess = (event) => {
                    const input = event.inputBuffer.getChannelData(0);
                    const output = event.outputBuffer.getChannelData(0);
                    output.set(input);

                    const s16Data = SessionJoinedHelpers.float32ToS16(input);
                    audioChunks.push({data: new Int16Array(s16Data), sampleRate});               
                };

                source = audioContext.createMediaStreamSource(stream);
                destination = audioContext.createMediaStreamDestination();
                source.connect(processor);
                processor.connect(destination);

                setTimeout(() => {
                    callback(audioChunks);                    
                    destination.disconnect();
                    source.disconnect();
                    processor.disconnect();
                }, returnEveryMs);

            })
            .catch(error => {
                console.log("was there an error?", error)
                // Handle the error when microphone access is denied or unavailable
                callback(error);
            });                   

    }

    static startStreamingToSocket(deviceId: string, session: any, callback: any) {

        this.deviceId = deviceId;
        this.type = "stream";
        this.lastAudioTime = Date.now();

        navigator.mediaDevices.getUserMedia({ audio: { deviceId } })
            .then(async (stream) => {

                // If session is not specified, this means to just change deviceId being captured and continue with same active publisher.
                // This happens when user switched microphone inputs from UI.
                if (session) {
                    this.publisher = window.Boost?.TranscriptionPublisher(session, "floor", "websocket");
                }

                const audioContext = new (window.AudioContext || window.webkitAudioContext)();

                const sampleRate = audioContext.sampleRate;
                const bufferSize = 4096; // Adjust buffer size as per your requirements

                // Below this level is considered silence.
                // Sometimes some environments produce small spikes of audio even if the microphone is muted.
                // This is the idea of this audio silence threshold.
                const silenceThreshold = 2 / 128;
                this.lastAudioTime = Date.now();

                const scriptNode = audioContext.createScriptProcessor(bufferSize, 1, 1);
                scriptNode.onaudioprocess = (event) => {
                    const input = event.inputBuffer.getChannelData(0);
                    const output = event.outputBuffer.getChannelData(0);
                    output.set(input);

                    const s16Data = SessionJoinedHelpers.float32ToS16(input);
                    this.publisher.stream(s16Data, sampleRate);

                    // search for a value above silence threshold and if found reset the silence timer
                    for (let i = 0; i < input.length; i++) {
                        if (input[i] < -silenceThreshold || input[i] > silenceThreshold) {
                            this.lastAudioTime = Date.now();
                            break;
                        }
                    }
                };

                const microphoneStream = audioContext.createMediaStreamSource(stream);
                const destinationNode = audioContext.createMediaStreamDestination();
                microphoneStream.connect(scriptNode);
                scriptNode.connect(destinationNode);

                this.listenerData = {
                    stream,
                    source: microphoneStream,
                    destination: destinationNode,
                    processor: scriptNode
                };

                callback(null, this.listenerData);

            })
            .catch(error => {
                console.log("was there an error?", error)
                // Handle the error when microphone access is denied or unavailable
                callback(error);
            });

    }

    static stopForwardingToSocket() {
        const data = this.listenerData;

        this.stopPublisher();
        if (data.stop) data.stop();

        this.type = "";
        this.listenerData = null;
        this.deviceId = null;
    }

    static startForwardingToSocket(session: any) {

        const publisher = this.publisher = window.Boost?.TranscriptionPublisher(session, "floor", "websocket");
        this.type = "forward";

        function onMessage(event) {
            // event.origin exampel: "http://example.org:8080"
            const data = event.data;
            if (data?.type === "audio-buffer") {
                if (data?.buffer) {
                    const s16Data = SessionJoinedHelpers.float32ToS16(data.buffer);
                    publisher.stream(s16Data, data.sampleRate);
                }
            }
        }

        window.addEventListener("message", onMessage);

        this.listenerData = {
            stop: () => removeEventListener("message", onMessage)
        };

        return this.listenerData;

    }

    static float32ToS16(input: any) {
        const output = new Int16Array(input.length);
        for (let i = 0; i < input.length; i++) {
            const sample = Math.max(-1, Math.min(1, input[i]));
            output[i] = sample < 0 ? sample * 0x8000 : sample * 0x7FFF;
        }
        return output.buffer;
    }

}

export default SessionJoinedHelpers;
