import {
  GET_ACTIVE_USER,
  GET_USER_SUBSCRIPTION,
  GET_USER_ORGANIZATIONS,
  SET_ACTIVE_USER,
  SET_USER_SUBSCRIPTION,
  SET_USER_ORGANIZATIONS,
} from "../Constants/user";

export function setUser(payload: any) {
  return { type: SET_ACTIVE_USER, payload };
}

export function getUser() {
  return { type: GET_ACTIVE_USER };
}

export function getUserSubscription() {
  return { type: GET_USER_SUBSCRIPTION };
}

export function setUserSubscription(payload: any) {
  return { type: SET_USER_SUBSCRIPTION, payload };
}

export function setUserOrganizations(payload: any) {
  return { type: SET_USER_ORGANIZATIONS, payload };
}

export function getUserOrganizations() {
  return { type: GET_USER_ORGANIZATIONS };
}
