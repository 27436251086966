// @ts-nocheck
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";


const SessionListen = () => {

    const [searchParams] = useSearchParams();

    // Create an AudioContext
    //const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    //let audioBuffer = null;

    /*function playPCMData(pcmData) {
        const sourceNode = audioContext.createBufferSource();
        sourceNode.connect(audioContext.destination);
        audioBuffer = audioContext.createBuffer(1, 4096, 16000);

        const channelData = audioBuffer.getChannelData(0);

        for (let i = 0; i < pcmData.length; i++) {
            channelData[i] = pcmData[i] / 32767; // Adjust this value to control the noise level
        }

        sourceNode.buffer = audioBuffer;
        sourceNode.start();

    }*/

    const listenForData = async () => {

        const sessionId = searchParams.get("sessionId");

        if (!sessionId) {
            return;
        }

    }

    useEffect(() => {

    }, []);

    return (
        <div>
            <button onClick={() => {
                listenForData();
            }}>
                Ok
            </button>
        </div>
    );
};


export default SessionListen;
