import ModelAbstract from '../ModelAbstract';

export default class KeywordsModel extends ModelAbstract {
	_id: string;
	name: string;

	constructor(data: any) {
		super();
		this._id = data._id;
		this.name = data.name;

		this.mapData();
	}

	mapData(): void {

	}

	validate(data: any): string {
		
		return "";

	}

	static handleError(error: Error): void {
		console.log(error);
	}
}
