import { useEffect, useState } from "react";
import LanguagesDropdownViewModel from "../Core/viewModel";
import BonfireAPI from "../../../Core/Data/Http";
import { GET_SPEECH_LANGUAGES } from "../../../Models/Languages/urls";
import LanguagesModel from "../../../Models/Languages";

export default function useLanguagesDropdown(props: {
  initialLanguages?: { value: string; label: string }[];
  initialLangCodes?: string[];
  onChange: (languages: { label: string; value: string }[]) => void;
  onLoaded: () => void;
  isMobile?: boolean;
}) {
  const [languagesSelected, setLanguagesSelected] = useState<any[]>([]);
  const [languagesList, setLanguagesList] = useState([]);

  useEffect(() => {
    loadLanguagesList();
  }, []);

  useEffect(() => {
    if (props.isMobile) {
      props.onChange(languagesSelected);
    }
  }, [languagesSelected]);

  const onChangeSelectedLanguages = (e: any) => {[]
    props.onChange(e);    
    setLanguagesSelected(e);
  };

  const loadLanguagesList = async () => {
    try {
      const data = await BonfireAPI.requestArray(GET_SPEECH_LANGUAGES, LanguagesModel);

      var languagesOptions;

      if (props.isMobile) {
        languagesOptions = (data as LanguagesModel[]).map((language) => {
          return { label: language.name, value: language.code };
        });
      } else {
        languagesOptions = (data as LanguagesModel[]).map((language) => {
          return { label: language.name, value: language.code };
        });
      }

      setLanguagesList(languagesOptions as any);

      if (props.initialLangCodes) {
        const foundLangsFromCodes = languagesOptions
          .filter((lang: any) => props.initialLangCodes?.includes(lang.value))

        setLanguagesSelected(foundLangsFromCodes);
      }

      if (props.initialLanguages) {
        setLanguagesSelected(props.initialLanguages);
      }

      props.onLoaded();
    } catch (error) {}
  };

  return {
    languagesList,
    languagesSelected,
    onChangeSelectedLanguages,
  };
}
