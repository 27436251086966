import AbstractAPI from './AbstractAPI';

let HttpModule: AbstractAPI;

if (process.env.REACT_APP_PLATFORM !== 'web') {
  HttpModule = require('./Mobile').default;
} else {
  HttpModule = require('./Web').default;
}

export default HttpModule;
