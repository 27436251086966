import {
  takeLatest,
  put,
  call,
  CallEffect,
  PutEffect,
} from "redux-saga/effects";
import { setActiveSessions } from "../Actions/dashboard";
import { setMeetingInstanceOptions } from "../Actions/session";
import { GET_MEETING_INSTANCE_OPTIONS, REQUEST_MEETING_INSTANCE_CAPTIONS, ALLOW_MEETING_INSTANCE_CAPTIONS } from "../Constants/session";
import BonfireAPI from "../../Http";
import {
  POST_MEETING_INSTANCE_OPTIONS,
  POST_MEETING_INSTANCE_REQUEST_CAPTIONS,
  POST_MEETING_INSTANCE_ALLOW_CAPTIONS
} from "../../../../Models/Sessions/urls";
import GeneralModel from "../../../../Models/General";

function* fetchMeetingInstanceOptions(action: any): Generator<
  | CallEffect<GeneralModel | Error>
  | PutEffect<{ type: string; payload: any }>,
  void,
  GeneralModel
> {
  try {
    const model = yield call(async () => {
      return await BonfireAPI.request(POST_MEETING_INSTANCE_OPTIONS, GeneralModel, {
        type: action.payload.type,
        clientId: action.payload.clientId,
        key: action.payload.key
      });
    });

    yield put(setMeetingInstanceOptions(model.data));
  } catch (error) {
    console.log("Error getting meeting instance options", error);
  }
}

function* requestMeetingInstanceCaptions(action: any): Generator<
  | CallEffect<GeneralModel | Error>
  | PutEffect<{ type: string; payload: any }>,
  void,
  GeneralModel
> {
  try {
    const model = yield call(async () => {
      return await BonfireAPI.request(POST_MEETING_INSTANCE_REQUEST_CAPTIONS, GeneralModel, action.payload);
    });

    yield put(setMeetingInstanceOptions(model.data));
  } catch (error) {
    console.log("Error requesting meeting instance captions", error);
  }
}

function* allowMeetingInstanceCaptions(action: any): Generator<
  | CallEffect<GeneralModel | Error>
  | PutEffect<{ type: string; payload: any }>,
  void,
  GeneralModel
> {
  try {
    const model = yield call(async () => {
      console.log('allowMeetingInstanceCaptions', action.payload)
      return await BonfireAPI.request(POST_MEETING_INSTANCE_ALLOW_CAPTIONS, GeneralModel, action.payload);
    });
    yield put(setMeetingInstanceOptions(model.data));
  } catch (error) {
    console.log("Error requesting meeting instance captions", error);
  }
}

function* sessionSaga() {
  yield takeLatest(GET_MEETING_INSTANCE_OPTIONS, fetchMeetingInstanceOptions);
  yield takeLatest(REQUEST_MEETING_INSTANCE_CAPTIONS, requestMeetingInstanceCaptions);
  yield takeLatest(ALLOW_MEETING_INSTANCE_CAPTIONS, allowMeetingInstanceCaptions);
}

export default sessionSaga;
