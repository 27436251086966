import { useState, useEffect, useRef } from "react";

export default function useBrowserExtension() {
  
  const [captureActive, setCaptureActive] = useState(false);
  const [error, setError] = useState(null);
  const [context, setContext] = useState<any>();
  const [inBrowserExtension, setInBrowserExtension] = useState(false);
  let hasParent = useRef(false);
  
  const sendToChomeExtension = (message: any) => {
    if (!hasParent.current) return;
    window.parent.postMessage(message, "*");
  };

  useEffect(() => {
    
    hasParent.current = !!(window.parent && window.parent !== window); // no parent window, no need to set up post-messaging between frames with parent
    
    function onMessage(event: any) {
      const message = event.data;
      if (message.type == "get-tab-context") {
        setContext(message.context); // contains information about the tab context of the browser tab the extension is started on (title, url, etc)
        setInBrowserExtension(message.context.subType == "tab-capture"); // set flag if we're in a browser extension
        console.log("get-tab-context", message);
      } else if (message.type === "error") {
        setError(message.error);
      } else if (message.type == "rec-start-failure") {
        setCaptureActive(false);
      } else if (message.type == "rec-start-success") {
        setCaptureActive(true);
      } else if (message.type == "recording-status") {
        setCaptureActive(!!message.active);
      }
    }
    
    if (hasParent.current) window.addEventListener("message", onMessage);
    
    sendToChomeExtension({ type: "get-tab-context"});
    
    return () => {
      hasParent.current && window.removeEventListener("message", onMessage)
    };
  }, []);

  return {
    context,
    error,
    captureActive,
    inBrowserExtension,
    sendToChomeExtension
  };
}
