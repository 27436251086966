import React from "react";
import ReactDOM from "react-dom/client";
import App from "./Navigation";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import theme from "./Lib/Theme/Light/theme";
import { Provider } from "react-redux";
import store from "./AppBase/Core/Data/Store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <ChakraProvider theme={theme}>
      <Provider store={store}>      
        <App />
      </Provider>
    </ChakraProvider>
  </>  
);
