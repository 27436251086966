import ModelAbstract from '../ModelAbstract';

export default class LanguagesModel extends ModelAbstract {
	code: string;
	name: string;

	constructor(data: any) {
		super();
		this.name = data.name;
		this.code = data.code;

		this.mapData();
	}

	mapData(): void {

	}

	validate(data: any): string {

		if (data.name) {
			return "";
		}

		if (data.error) {
			return data.error;
		}

		return "Unexpected error";

	}

	static handleError(error: Error): void {
		console.log(error);
	}
}
