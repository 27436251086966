import {
    MIC_INPUT_CHANGE,
    MIC_AUDIO_DETECTED,
    MIC_AUDIO_STATUS,
    MIC_ACCESS_ALLOWED,
} from "../Constants/media";

export default function MediaReducer(
  state = {
    micInput: "default", // "default" is the default microphone deviceId in case it's not specified this is the mic device from which audio getUserMedia() reads
    micAudioDetected: false, // status of current microphone audio detection
    micAudioStatus: "",
    micAccessAllowed: false,
  },
  action: any
) {
    if (action.type === MIC_INPUT_CHANGE) {
        return {
            ...state,
            micInput: action.payload,
        };
    } else if (action.type === MIC_AUDIO_DETECTED) {
        return {
            ...state,
            micAudioDetected: action.payload,
        };
    } else if (action.type === MIC_AUDIO_STATUS) {
        return {
            ...state,
            micAudioStatus: action.payload,
        };
    } else if (action.type === MIC_ACCESS_ALLOWED) {
        return {
            ...state,
            micAccessAllowed: action.payload,
        };
    }
    return state;
};
