import { Box, Text, Spinner } from "@chakra-ui/react";
import T from "../../../../../Core/Translations";

const SessionLoadState = ({ sessionReady }: { sessionReady: boolean }) => {
  return (
    <Box
      m={20}
      alignItems={"center"}
      justifyContent={"center"}
      display={"flex"}
      flexDirection={"column"}
    >
      <Spinner m={5} />
      {!sessionReady ? (
        <Text fontSize="xs">{T.get("Initializing.. Please wait...")}</Text>
      ) : null}
    </Box>
  );
};

export default SessionLoadState;
