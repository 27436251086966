import { SET_ANNOTATIONS_REACTION, SET_ANNOTATIONS_MESSAGE, SET_ANNOTATING_MESSAGE } from "../Constants/annotations";

export function setAnnotationsReaction(payload: any) {
  return {
    type: SET_ANNOTATIONS_REACTION,
    payload,
  };
}

export function setAnnotationsMessage(payload: any) {
    return {
        type: SET_ANNOTATIONS_MESSAGE,
        payload,
    };
} 

export function setAnnotatingMessage(payload: any) {
    return {
        type: SET_ANNOTATING_MESSAGE,
        payload,
    };
}