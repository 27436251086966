import {
  takeLatest,
  put,
  call,
  CallEffect,
  PutEffect,
} from "redux-saga/effects";
import { setActiveSessions } from "../Actions/dashboard";
import { GET_ACTIVE_SESSIONS } from "../Constants/dashboard";
import BonfireAPI from "../../Http";
import { GET_USER_SESSIONS } from "../../../../Models/Sessions/urls";
import SessionsModel from "../../../../Models/Sessions";

function* fetchActiveSessions(): Generator<
  | CallEffect<SessionsModel[] | Error>
  | PutEffect<{ type: string; payload: any }>,
  void,
  SessionsModel[]
> {
  try {
    const sessions = yield call(async () => {
      return await BonfireAPI.requestArray(GET_USER_SESSIONS, SessionsModel);
    });

    yield put(setActiveSessions(sessions));
  } catch (error) {
    console.log("Error getting active sessions", error);
  }
}

function* dashboardSaga() {
  yield takeLatest(GET_ACTIVE_SESSIONS, fetchActiveSessions);
}

export default dashboardSaga;
