import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser, getUserOrganizations } from "../Data/Store/Actions/user";
import { RootState } from "../Data/Store/Reducers";
import UserModel from "../../Models/Users";

export default function useUser() {
  const dispatch = useDispatch();

  const getActiveUser = async () => {
    dispatch(getUser());
  };

  const getOrganizations = async () => {
    dispatch(getUserOrganizations());
  };

  useEffect(() => {
    getActiveUser();
    getOrganizations();
  }, []);

  const user = useSelector(
    (state: RootState) => state.user.activeUser as UserModel
  );

  const organizations = useSelector(
    (state: RootState) => state.user.organizations
  );

  return { user, organizations };
}
