import React, { ChangeEvent, useState } from 'react';
import { Box, IconButton, Flex, Button, Textarea, useMediaQuery, useColorMode } from '@chakra-ui/react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faStar
} from "@fortawesome/free-solid-svg-icons";

interface StarRatingProps {
    totalStars?: number;
    onRatingChange: (rating: number) => void;
    handleFeedbackSubmit: (feedback: string) => void;
}

const StarRating: React.FC<StarRatingProps> = ({ totalStars = 5, onRatingChange, handleFeedbackSubmit }) => {
    const [rating, setRating] = useState<number>(0);
    const [hoverRating, setHoverRating] = useState<number>(0);
    const [feedbackInput, setFeedbackInput] = useState<string>('');
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
    const [isLargerThan568] = useMediaQuery("(min-width: 568px)");
    
    const { colorMode } = useColorMode();
    
    const handleClick = (index: number) => {
        setRating(index);
        if (onRatingChange) {
            onRatingChange(index);
        }
    };

    const onSubmit = () => {
        handleFeedbackSubmit(feedbackInput);
        setIsSubmitDisabled(true);
    };

    const handleMouseEnter = (index: number) => {
        setHoverRating(index);
    };

    const handleMouseLeave = () => {
        setHoverRating(0);
    };

    const onInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setFeedbackInput(event.target.value);
    }

    return (
        <Flex flexDirection={"column"} alignItems={'center'} >
            <Flex flexDirection={'row'} my={3}>
                {Array.from({ length: totalStars }, (_, index) => index + 1).map((star) => (
                    <Box
                        display={'flex'}
                        key={star}
                        onClick={() => handleClick(star)}
                        onMouseEnter={() => handleMouseEnter(star)}
                        onMouseLeave={handleMouseLeave}

                    >
                        <IconButton
                            m={1}
                            isDisabled={rating !== 0}
                            border={0}
                            variant="beOutline"
                            aria-label="star"
                            boxSize={8}
                            color={hoverRating >= star || rating >= star ? 'yellow.400' : 'gray.300'}
                            icon={<FontAwesomeIcon icon={faStar} size={"xl"} />}
                        />
                    </Box>
                ))}
            </Flex>
            <Box w={isLargerThan568 ? "50%" : "100%"}>
                <Textarea
                    mb={3} onChange={onInputChange} placeholder="Add extra feedback or comments here"
                    size='sm'
                    bg={colorMode === 'light' ? 'white' : 'black'}   
                />
                <Button onClick={onSubmit} variant={"beSecondary"} isDisabled={feedbackInput.length === 0 || isSubmitDisabled} width={"100%"}>Submit</Button>
            </Box>
        </Flex>
    );
};

export default StarRating;