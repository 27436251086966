// @ts-nocheck
import ModelAbstract from "../ModelAbstract";

export default class UserModel extends ModelAbstract {
  name: string;
  email: string;
  avatarUrl: string;
  id: string;
  selectedOrganizationId: string;
  activationCode: string;

  constructor(data: any) {
    super();

    this.name = data.name;
    this.email = data.email;
    this.avatarUrl = data.avatarUrl;
    this.id = data.id;
    this.selectedOrganizationId = data.selectedOrganizationId;
    this.activationCode = data.activationCode;

    this.mapData();
  }

  mapData(): void {}

  validate(data: any): string {
    if (data.email) {
      return null;
    }

    if (data.error) {
      return data.error;
    }

    return "Unexpected error";
  }

  static handleError(error: Error): void {
    console.log(error);
  }
}
