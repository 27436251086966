import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Text,
  Box,
  Select,
  Center,
  Button,
  Flex,
} from "@chakra-ui/react";

import { getMediaDevices } from "../../../../../../Lib/Utils/media-devices";
import { RootState } from "../../../../../Core/Data/Store/Reducers";
import { setMicInput, setMicAudioDetected, setMicAudioStatus, setMicAccessAllowed } from "../../../../../Core/Data/Store/Actions/media";

import store from "../../../../../Core/Data/Store";
import { faWarning, faCheckCircle, faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

handleChangeOfMicrophone();

// TODO: this function can be moved to a separate file as background service
// It registers to handle microphone access changes and dispatches the relevant messages, so each component which needs them updates appropriately.
function handleChangeOfMicrophone() {
  // detecting current state of microphone and handling live change of settings
  navigator.permissions?.query({ name: 'microphone' } as unknown as PermissionDescriptor)
  .then(result => {
    updateStatus(result);

    result.onchange = function (e) {
      updateStatus(e.currentTarget);
    };

    function updateStatus(result: any) {
      if (result.state == 'granted') {
        store.dispatch(setMicAudioStatus("Audio is not detected yet."));
        store.dispatch(setMicAccessAllowed(true));
      } else if (result.state == 'prompt') {
        store.dispatch(setMicAudioStatus("Waiting for microphone permissions."));
        store.dispatch(setMicAudioDetected(false));
        store.dispatch(setMicAccessAllowed(false));
      } else if (result.state == 'denied') {
        store.dispatch(setMicAudioStatus("Microphone access denied."));
        store.dispatch(setMicAudioDetected(false));
        store.dispatch(setMicAccessAllowed(false));
      }
    }
  })
  .catch(exc => {
    console.error("Can't access permissions:", exc);
  });
}

export default function MicrophoneInfo(
  { showSelect, microphoneSettingsClicked } : 
  { microphoneSettingsClicked?: () => void, showSelect?: boolean }) {
  
  const dispatch = useDispatch();
  
  const [microphones, setMicrophones] = useState<any[]>([]);
  const [volume, setVolume] = useState(0);
  const mediaData = useRef<any>();
  
  const micInput = useSelector(
    (state: RootState) => state?.media?.micInput
  );
  const micAudioDetected = useSelector(
    (state: RootState) => state?.media?.micAudioDetected
  );
  const micAudioStatus = useSelector(
    (state: RootState) => state?.media?.micAudioStatus
  );
  const micAccessAllowed = useSelector(
    (state: RootState) => state?.media?.micAccessAllowed
  );

  useEffect(() => {
    getMediaDevices({ kind: "audioinput" }).then(deviceInfos => {
      setMicrophones(deviceInfos);
    });

    return () => stopMedia(); // stop media capture when component gets removed from UI
  }, []);

  useEffect(() => {
    stopMedia();
    dispatch(setMicAudioDetected(false));
    dispatch(setMicAudioStatus("Audio is not detected yet."));
    getMedia(micInput);
  }, [micInput]);

  useEffect(() => {
    if (micAccessAllowed) getMedia(micInput);
    else stopMedia();
  }, [micAccessAllowed]);
  
  const getMedia = (deviceId: string) => {
    if (!deviceId) return; // if no media input is specified do not activate indicator
    if (mediaData.current?.deviceId == deviceId) return;
    
    stopMedia();
    
    mediaData.current = { deviceId };
    
    navigator.mediaDevices.getUserMedia({ audio: { deviceId } })
    .then(async (stream) => {
      
        //const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const audioContext = new AudioContext();

        const bufferSize = 4096; // Adjust buffer size as per your requirements

        const scriptNode = audioContext.createScriptProcessor(bufferSize, 1, 1);
        scriptNode.onaudioprocess = (event) => {
            const input = event.inputBuffer.getChannelData(0);
            const output = event.outputBuffer.getChannelData(0);
            output.set(input);

            // Some weird OS issues and workarounds:
            // - even if my mic is muted through the hardware switch, I do receive some very small sound levels
            // - when we access to read the microphone, there's also some bigger spike of volume (even when soundcard is muted)
            const silenceThreshold = 2 / 128; // set a silence threshold so everything below it is considered silence
            let minCount = 250; // allow at least a few samples of non-silence to consider we have an audio

            let max = 0;
            for (let i = 0; i < input.length; i++) {
                const val = Math.abs(input[i]);
                if (val > max) max = val;
                if (val > silenceThreshold) minCount--;
            }
            setVolume(Math.min(max, 1));
            
            if (max > silenceThreshold && minCount < 0) {
              dispatch(setMicAudioStatus("Audio is detected"));
              dispatch(setMicAudioDetected(true));
            }
        };

        const microphoneStream = audioContext.createMediaStreamSource(stream);
        const destinationNode = audioContext.createMediaStreamDestination();
        microphoneStream.connect(scriptNode);
        scriptNode.connect(destinationNode);
        
        mediaData.current = {
          deviceId,
          audioContext,
          stream,
          source: microphoneStream,
          destination: destinationNode,
          processor: scriptNode
        };
    })
    .catch(error => {
        console.log("Can't access microphone", error)
        dispatch(setMicAudioStatus("Access to microphone is not allowed.")) ;
    });
  };
  
  const stopMedia = () => {
    if (!mediaData.current) return;
    
    const { audioContext, stream, destination, source, processor } = mediaData.current;
    
    const audioTracks = stream?.getAudioTracks();
    audioTracks?.forEach((track: any) => track.stop());

    destination?.disconnect();
    source?.disconnect();
    processor?.disconnect();
    
    audioContext?.close();
    
    mediaData.current = null;
  };

  const selectMicrophone = (e: any) => {
    dispatch(setMicInput(e.target.value)); // deviceId from enumerateDevices()
  };
  
  return (
    <Box w="100%" position="relative">
      {showSelect && <Select onChange={selectMicrophone} w="100%" overflow="hidden">
        {microphones.map(deviceInfo => (
          <option value={deviceInfo.deviceId} key={deviceInfo.deviceId} selected={micInput == deviceInfo.deviceId}>{deviceInfo.label}</option>
        ))}
      </Select>}
      <Flex flexDirection={"row"} alignItems={"center"}>

        <Box mt={"10px"} mr={2}>
          <FontAwesomeIcon icon={faMicrophone} color={"#777"} />  
        </Box>    
        
        <div className="volume-indicator">
          <div className="volume-indicator-bar" style={{width: Math.floor(volume*100) + "%"}}></div>
        </div>

      </Flex>     
      <Center>
        <Flex flexDirection="row" mt={3}>
          <Box fontSize={"sm"} flexDirection={"row"} display="flex" alignItems={"flex-start"}>
            {micAudioDetected ? 
            <FontAwesomeIcon icon={faCheckCircle} color={"green"} style={{marginTop: "5px"}} /> :
            <FontAwesomeIcon icon={faWarning} color={"#f6ad55"} style={{marginTop: "5px"}} />} 
            <Box ml={1} display={"inline-block"}>
              {micAudioStatus}                            
            </Box>            
          </Box>          
          {!micAudioDetected && !showSelect && microphoneSettingsClicked &&
                <Box onClick={() => { microphoneSettingsClicked() }} ml={1} cursor={"pointer"}>            
                  <Text color={"#25569c"} fontWeight={"600"} fontSize={"sm"}>
                    Microphone settings
                  </Text>
                </Box>}
        </Flex>        
      </Center>      
    </Box>
  );
}
