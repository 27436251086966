import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const beVariant = defineStyle({
	borderRadius: '23px',
	padding: '5px 11px',
	fontSize: '14px',
	fontWeight: '600',
	textTransform: 'capitalize',
});

const aiProTag = defineStyle({
	...beVariant,
	borderRadius: '8px',
	padding: '2px 11px',
});

export const badgeTheme = defineStyleConfig({ variants: { beVariant, aiProTag } });
