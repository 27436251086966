import {
  GET_MEETING_INSTANCE_OPTIONS,
  SET_MEETING_INSTANCE_OPTIONS,
  REQUEST_MEETING_INSTANCE_CAPTIONS,
  ALLOW_MEETING_INSTANCE_CAPTIONS
} from '../Constants/session';

export function getMeetingInstanceOptions(type: string, clientId: string, key: string) {
  return { type: GET_MEETING_INSTANCE_OPTIONS, payload: { type, clientId, key } };
}

export function setMeetingInstanceOptions(data: any) {
  return { type: SET_MEETING_INSTANCE_OPTIONS, payload: data };
}

export function requestMeetingInstanceCaptions(type: string, clientId: string, key: string) {
  return { type: REQUEST_MEETING_INSTANCE_CAPTIONS, payload: { type, clientId, key } };
}

export function allowMeetingInstanceCaptions(type: string, clientId: string, key: string, captionsAllowed: boolean) {
  return { type: ALLOW_MEETING_INSTANCE_CAPTIONS, payload: { type, clientId, key, captionsAllowed } };
}