import {
  MIC_INPUT_CHANGE,
  MIC_AUDIO_DETECTED,
  MIC_AUDIO_STATUS,
  MIC_ACCESS_ALLOWED,
} from "../Constants/media";

export function setMicInput(payload: any) {
  return { type: MIC_INPUT_CHANGE, payload };
}

export function setMicAudioDetected(payload: any) {
  return { type: MIC_AUDIO_DETECTED, payload };
}

export function setMicAudioStatus(payload: any) {
  return { type: MIC_AUDIO_STATUS, payload };
}
export function setMicAccessAllowed(payload: any) {
  return { type: MIC_ACCESS_ALLOWED, payload };
}
