import {
  SET_ANNOTATING_MESSAGE,
  SET_ANNOTATIONS_MESSAGE,
  SET_ANNOTATIONS_REACTION,
} from "../Constants/annotations";

export default function AnnotationsReducer(
  state = {
    reactions: new Map(),
    messages: new Map(),
    annotatingMessage: "",
  },
  action: any
) {
    if(action.type === SET_ANNOTATIONS_MESSAGE) {
        return {
            ...state,
            messages: action.payload,
        };
    } else if(action.type === SET_ANNOTATIONS_REACTION) {
        return {
            ...state,
            reactions: action.payload,
        };
    } else if(action.type === SET_ANNOTATING_MESSAGE) {
        return {
            ...state,
            annotatingMessage: action.payload,
        };
    }

    return state;
};
