import { useState, useRef, useEffect } from "react";
import SessionJoinedHelper from "../../Screens/SessionJoined/Helpers";
import LanguagesModel from "../../Models/Languages";
import { GET_TRANSCRIPT } from "../../Models/TranscriptionMessage/urls";
import TranscriptionMessageModel from "../../Models/TranscriptionMessage";
import BonfireAPI from "../Data/Http";
import { AUTH_SESSION, TIMEOUT_SESSION } from "../../Models/SessionsAuth/urls";
import SessionsAuthModel from "../../Models/SessionsAuth";
import { GET_LANGUAGES } from "../../Models/Languages/urls";
import { getActiveSessions } from "../Data/Store/Actions/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Data/Store/Reducers";
import CreateSessionViewModel from "../../Screens/CreateSession/Core/viewModel";
import GeneralModel from "../../Models/General";
import { useSearchParams } from "react-router-dom";
import { text } from "stream/consumers";
import { setAnnotatingMessage } from "../Data/Store/Actions/annotations";
import Translations from "../Translations";

import { PARTICIPANTS_COLORS } from "../../../Lib/Theme/Light/colors";

export const participantsColors = PARTICIPANTS_COLORS;

export const loadMessages = async (language: any, sessionId: string) => {
  try {
    const messages = await BonfireAPI.requestArray(
      GET_TRANSCRIPT,
      TranscriptionMessageModel,
      {
        language,
        sessionId,
      }
    );

    const formattedMessages = SessionJoinedHelper.formatMessages(
      messages as TranscriptionMessageModel[]
    );

    return formattedMessages;
  } catch (error) {
    return [];
  }
};

export default function useSessionJoined(
  sessionId: string,
  sessionJoinedPlatformHelper: any,
  transcriptionSdk: any,
  sessState: any,
  goBack: () => void,
  onunauthorized?: () => void,
  onNotificationReceived?: (message: any) => void,
  inBrowserExtension?: boolean,
  onPlayTextToSpeech?: (bytes: any) => void
) {
  const dispatch = useDispatch();

  const micInput = useSelector(
    (state: RootState) => state?.media?.micInput
  );

  const [statusChangeReason, setStatusChangeReason] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [provider, setProvider] = useState("");
  const [languagesList, setLanguagesList] = useState<LanguagesModel[]>([]);
  const [languages, setLanguages] = useState<any[]>([]);
  const [uniqueLanguages, setUniqueLanguages] = useState<any[]>([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [sessionState, setSessionState] = useState<any>(null);
  const [currentLanguage, setCurrentLanguage] = useState<string>("en");
  const currentLanguageRef = useRef<string>("en");
  const [temporarySentence, setTemporarySentence] = useState<any>(null);
  const [finalSentences, setFinalSentences] = useState<any>([]);
  const [sessionReady, setSessionReady] = useState(false);
  const sessionReadyInterval = useRef<any>(null);
  const isInitialized = useRef(false);
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);
  const [statusLoading, setStatusLoading] = useState(false);
  const [sessionLiveMinutesUsed, setSessionLiveMinutesUsed] = useState(0);
  const [languageRecognized, setLanguageRecognized] = useState("");
  const [isSpeechToSpeechModeEnabled, setIsSpeechToSpeechModeEnabled] = useState(false);
  const [showSilenceWarning, setShowSilenceWarning] = useState(false);
  const silenceCheckTimer = useRef<any>();
  const silenceShown = useRef(false);

  const session = useRef<any>(null);
  const subscription = useRef<any>(null);
  const finalSentencesRef = useRef<any>([]);
  const textToSpeechBytes = useRef<any>({});
  const textToSpeechEnabled = useRef(false);
  const notificationsShown = useRef<string[]>([]);

  const silenceWarningTimeout = 3; // in seconds

  useEffect(() => {
    // we show microphone silence warning only once when we detect it, then when sound comes in we reset it and never show it again even if we encounter no audio again
    silenceCheckTimer.current = setInterval(() => {
      const newShowSilenceWarning = (sessionJoinedPlatformHelper.getSilenceTime() > silenceWarningTimeout);
      setShowSilenceWarning(newShowSilenceWarning);

      if (newShowSilenceWarning) silenceShown.current = true;

      if (silenceShown.current && !newShowSilenceWarning) {
        stopSilenceCheck(); // in case we've shown a silence warning and sound has started, clear the timer
        setShowSilenceWarning(false);
      }
    }, 1000);
    return () => stopSilenceCheck();
  }, []);

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
  }, [currentLanguage]);

  useEffect(() => {
    finalSentencesRef.current = finalSentences;
  }, [finalSentences]);

  useEffect(() => {
    // Change of current microphone input device, must switch to recording from it.
    sessionJoinedPlatformHelper.changeMicDeviceId(micInput, (err: any) => {
      // TODO: handle any error while trying to capture from another microphone device
    });
  }, [micInput]);

  useEffect(() => {
    const uniqueLanguages = Array.from(new Set(languages.map(lang => lang.key))).map(key => {
      return languages.find(lang => lang.key === key);
    });
    setUniqueLanguages(uniqueLanguages);
  }, [languages]);

  const stopSilenceCheck = () => {
    if (!silenceCheckTimer.current) return;
    clearInterval(silenceCheckTimer.current);
    silenceCheckTimer.current = null;
  };

  const initiliazeBCap = async () => {
    if (isInitialized.current) {
      return;
    }

    isInitialized.current = true;

    updateInitialLanguages();

    var speakingLanguage = "en";

    if (sessionState.session.session.speakingLanguage[0]) {
      speakingLanguage =
        sessionState.session.session.speakingLanguage[0].split("-")[0];
    }

    let messages;

    if (sessionState.session.session.speakingLanguage.length > 1) {
      setCurrentLanguage('floor');
      messages = await loadMessages('floor', sessionId);
    } else {
      setCurrentLanguage(speakingLanguage);
      messages = await loadMessages(speakingLanguage, sessionId);
    }

    setFinalSentences(messages);    

    if (sessionState?.session?.session.eventType) {
      setProvider(sessionState.session.session.eventType);
    }

    const status = sessionState.session.session.status;

    if (status === "initialized") {
      sessionReadyInterval.current = SessionJoinedHelper.waitForSessionReady(
        sessionId,
        async (session, doRetry) => {
          if (session.status === "initialized") {
            if (doRetry) {
              await BonfireAPI.request(TIMEOUT_SESSION, GeneralModel, {
                sessionId,
              });
            }
            return;
          }
          var sessionNew = { ...sessionState };
          sessionNew.session.session = session;
          setSessionState(sessionNew);
          setSessionReady(true);
        }
      );
      setIsLoading(false);
    } else {
      setSessionReady(true);
    }
  };

  const toggleTTS = (isEnabled: boolean) => {
    textToSpeechEnabled.current = isEnabled;
    if (subscription.current) {
      subscription.current.setAudioEnabled(isEnabled);
    }
  }

  const revisionsReceived = (transcriptions: any[]) => {
    var isUpdated = false;

    transcriptions.map((updatedTranscription) => {
      const message = updatedTranscription.messages[0];
      for (var key in finalSentencesRef.current) {
        const finalSentence = finalSentencesRef.current[key];
        if (finalSentence.id === message.id) {
          finalSentence.message = message.message;
          finalSentence.isRevised = true;
          isUpdated = true;
          break;
        }
      }
    });

    if (isUpdated) {
      setFinalSentences((prevFinalSentences: any) => [...prevFinalSentences]);
    }
  };

  const updateInitialLanguages = async (forceSessionState = null) => {
    var sessionInfo = sessionState;

    if (forceSessionState) {
      sessionInfo = forceSessionState;
    }

    var initialLanguages: any[] = [];

    sessionState.session.session.speakingLanguage.map((speakingLanguage: string) => {
      const language = speakingLanguage.split("-")[0];
      initialLanguages.push({
        key: language,
        name: SessionJoinedHelper.findLanguageByCode(
          languagesList,
          language
        ).name,
        isSpeakingLanguage: true,
      });
    });

    if (sessionInfo.session.session.languages?.length > 0) {
      sessionInfo.session.session.languages.map((language: any) => {
        const isExist = initialLanguages.find((lang) => {
          if (lang.key === language) {
            lang.isTranslationLanguage = true;
            return true;
          }
          return false;
        });
        if (isExist) {
          return false;
        }
        initialLanguages.push({
          key: language,
          name: SessionJoinedHelper.findLanguageByCode(languagesList, language)
            .name,
        });
        return true;
      });
    }

    if (sessionState.session.session.speakingLanguage.length > 1) {
      initialLanguages.unshift({
        key: "floor",
        name: Translations.get("Original"),
      });
    }
    if (sessionInfo.session.sessionContext.speechLanguages?.length > 0) {
      setIsSpeechToSpeechModeEnabled(true);
    }

    setLanguages(initialLanguages);

    const isSelectedExist = initialLanguages.some(
      (language) => currentLanguageRef.current == language.key
    );
    if (!isSelectedExist) {
      languageChanged(initialLanguages[0].key);
    }
  };

  const hideAnnotations = () => {
    dispatch(setAnnotatingMessage(""));
  }

  const startCaptioning = async () => {
    if (session.current) {
      return;
    }

    try {
      session.current = transcriptionSdk.TranscriptionSession(
        sessionState.session.raw
      );
      subscription.current = transcriptionSdk.TranscriptionSubscriber(
        session.current,
        currentLanguage,
        sessionState.websocket || "websocket"
      );

      const status = sessionState.session.session?.status;

      if (
        sessionState.session.isOwner &&
        sessionState.session.session?.eventType === "local" &&
        status != "stopped" &&
        status != "paused" &&
        status != "waiting"
      ) {
        startStreamingSocket();
      }

      subscription.current!.onAudio = (data: any, language: string, id: string, part: number, maxParts: number) => {

        if (!textToSpeechEnabled.current) {
          return;
        }

        if (!textToSpeechBytes.current[id]) {
          textToSpeechBytes.current[id] = {
            chunks: new Array(maxParts),
            chunksAdded: 0,
            created: new Date().getTime()
          };
        }

        textToSpeechBytes.current[id].chunks[part] = data;
        textToSpeechBytes.current[id].chunksAdded++;

        if (textToSpeechBytes.current[id].chunksAdded === maxParts) {

          const totalLength = textToSpeechBytes.current[id].chunks.reduce((acc: any, int16Array: any) => acc + int16Array.length, 0);
          const playSoundBytes = new Int16Array(totalLength);
          let currentIndex = 0;

          for (const int16Array of textToSpeechBytes.current[id].chunks) {
            playSoundBytes.set(int16Array, currentIndex);
            currentIndex += int16Array.length;
          }

          if (onPlayTextToSpeech) {
            onPlayTextToSpeech(playSoundBytes);
          }
          delete textToSpeechBytes.current[id];

        }

      }

      subscription.current!.onLiveUpdate = (data: any) => {
        dataReceived(data);
      };

      subscription.current!.onReconnect = async () => {
        console.log("Websocket has been reconnected");
        if (!sessionState.session.isOwner) {
          const sessionStateUpdated = await SessionJoinedHelper.getLatestSessionInfo(sessionState.session.sessionId);
          const sessionNew: any = { ...sessionState };
          sessionNew.session.session = sessionStateUpdated;
          setSessionState(sessionNew);
          languageChanged(currentLanguageRef.current);
          updateInitialLanguages(sessionNew);
        }
      }

      subscription.current!.onMessage = (data: any) => {
        //console.log("got custom message!", data);
        var sessionNew;
        if (data.type === "statuschange") {
          sessionNew = { ...sessionState };
          sessionNew.session.session.status = data.data.status;
          // We're not reloading the session when state changes just setting status.
          // This way events array is not up to date.
          // Add manually these events (though they're not in full data).
          // What we need is to be able to find if there's a started event.
          sessionNew.session.session.events.push({
            eventName: data.data.status,
            timeStamp: new Date(),
            runtime: true // mark this event that it has been constructed manually runtime
          });
          setSessionState(sessionNew);
          if (data.data.reason) {
            setStatusChangeReason(data.data.reason);
          }
        } else if (data.type === "languageschange") {
          sessionNew = { ...sessionState };
          sessionNew.session.session.languages = data.data.languages;
          setSessionState(sessionNew);
          updateInitialLanguages(sessionNew);
        } else if (data.type === "notification") {
          if (onNotificationReceived) {
            if (data.data.id) {
              if (notificationsShown.current.includes(data.data.id)) {
                return;
              }
              notificationsShown.current.push(data.data.id);
            }
            onNotificationReceived(data.data);
          }
        } else if (data.type === "revision") {
          revisionsReceived(data.data.transcriptions);
        } else if (data.type === "info") {
          if (data?.data?.minutesUsed) {
            setSessionLiveMinutesUsed(data.data.minutesUsed);
          }
        }
      };
    } catch (error) {
      console.log("Connection error", error);
    }

    setIsLoading(false);
  };

  const startStreamingSocket = async () => {
    if (sessionJoinedPlatformHelper.isActive()) {
      return;
    }

    if (inBrowserExtension) {
      sessionJoinedPlatformHelper.startForwardingToSocket(session.current);
    } else {
      // quick-local
      await sessionJoinedPlatformHelper.startStreamingToSocket(
        micInput, // mic deviceId string
        session.current,
        (error: any) => {
          // TODO
        }
      );
    }
  };

  const dataReceived = (data: any) => {
    if (!data) return; // message could be empty if user has removed everything he was typing live
    const transcriptionData = new TranscriptionMessageModel({ messages: [data] });
    const message = SessionJoinedHelper.formatMessage(transcriptionData);

    // Fixing the race control when user selects language, but incoming messages have still prev language
    if (currentLanguageRef.current != message.language) {
      return;
    }

    if (data.original) {
      setLanguageRecognized(data.original.langCode);
    } else {
      setLanguageRecognized(data.langCode);
    }

    if (!data.isFinal) {
      setTemporarySentence(message);
    } else {
      setTemporarySentence(null);
      setFinalSentences((prevState: any) => {
        const updatedMessages = [...prevState];
        const lastIndex = updatedMessages.length - 1;
        const speakerIsDifferent =
          updatedMessages[lastIndex]?.participantName !==
          message?.participantName;

        const lastMessageEndsWithPeriod =
          SessionJoinedHelper.messageEndsWithPeriod(
            updatedMessages[lastIndex]?.message
          );

        if (
          !lastMessageEndsWithPeriod &&
          updatedMessages.length > 0 &&
          !speakerIsDifferent
        ) {
          updatedMessages[lastIndex].message += " " + message.message;
        } else {
          updatedMessages.push(message);
        }

        return updatedMessages;
      });
    }
  };

  const getSessionData = async () => {
    const sessionData = await BonfireAPI.request(
      AUTH_SESSION,
      SessionsAuthModel,
      { sessionId }
    );
    setSessionState({ ...sessState, session: sessionData });
  };

  const initialize = async () => {
    try {
      const data = await BonfireAPI.requestArray(GET_LANGUAGES, LanguagesModel);
      setLanguagesList(data as LanguagesModel[]);
      await getSessionData();
    } catch (error) {
      console.log("There was an error", error);
      if (onunauthorized) {
        onunauthorized();
      }
    }
  };

  useEffect(() => {
    initialize();
    return () => {
      stopStreamingSocket();

      if (subscription.current) {
        subscription.current.disconnect();
        subscription.current = null;
        session.current = null;
      }
    };
  }, []);

  const stopStreamingSocket = async () => {
    sessionJoinedPlatformHelper.stopAudioToSocket();
  };

  const languageChanged = async (language: string) => {
    if (!subscription.current) return;

    hideAnnotations();
    setTemporarySentence(null);
    setCurrentLanguage(language);
    setLoadingMessages(true);

    const messages = await loadMessages(language, sessionId);

    setFinalSentences(messages);

    var langCode = language || "floor";
    subscription.current.setLanguage(langCode);

    setLoadingMessages(false);
  };

  useEffect(() => {
    if (sessionReady) {
      startCaptioning();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionReady]);

  useEffect(() => {
    if (sessionState) {
      initiliazeBCap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionState]);

  const stopSession = async () => {
    setIsLoading(true);

    try {
      await CreateSessionViewModel.stopSession(
        sessionState!.session.sessionContext.eventId
      );
    } catch (error) { }

    dispatch(getActiveSessions());

    if (sessionState.session?.session.eventType === "local") {
      stopStreamingSocket();
      goBack();
      setIsLoading(false);
      await getSessionData();
      return;
    }

    await getSessionData();

    setIsLoading(false);
  };

  const startSession = async () => {
    setStatusLoading(true);

    try {
      await CreateSessionViewModel.startSession(sessionId);
    } catch (error) { }

    dispatch(getActiveSessions());

    if (sessionState.session?.session.eventType === "local") {
      startStreamingSocket();
    }

    await getSessionData();
    setStatusChangeReason('');

    setStatusLoading(false);
  };

  const pauseSession = async () => {
    setStatusLoading(true);

    try {
      await CreateSessionViewModel.pauseSession(sessionId);
    } catch (error) { }

    dispatch(getActiveSessions());

    if (sessionState.session?.session.eventType === "local") {
      stopStreamingSocket();
    }

    await getSessionData();

    setStatusLoading(false);
  };

  return {
    languageChanged,
    stopSession,
    startSession,
    pauseSession,
    isLoading,
    languages,
    uniqueLanguages,
    isSpeechToSpeechModeEnabled,
    loadingMessages,
    currentLanguage,
    temporarySentence,
    finalSentences,
    participantsColors,
    sessionState,
    subscription,
    sessionReady,
    provider,
    isAutoScrollEnabled,
    setIsAutoScrollEnabled,
    statusLoading,
    sessionLiveMinutesUsed,
    statusChangeReason,
    languageRecognized,
    toggleTTS,
    hideAnnotations,
    showSilenceWarning,
  };
}
