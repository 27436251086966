import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  useDisclosure,
  IconButton,
  Image,
  Text,
  Flex,
  Box,
  Button,
  useClipboard,
  Tooltip,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import config from "../../../../../Config";

export function ShareInfo({ sessionId }: { sessionId: any }) {
  const linkToJoin = `${config.webapp.url}/session-joined/${sessionId}`;

  const { onCopy, hasCopied } = useClipboard(linkToJoin);

  return (
    <Box bg="white" p="12px" borderRadius="4px">
      <Image alt="Share session QR code" src={`${config.api.url}/sessions/qr/${sessionId}`} />
      <Flex bg="mutedBlue" p="4px" borderRadius="4px" alignItems="center">
        <Text noOfLines={1} whiteSpace="nowrap" variant="beTextDescription">
          {linkToJoin}
        </Text>
        <Button onClick={onCopy} size="xs" ml="30px" variant="bePrimary">
          {hasCopied ? "Copied!" : "Copy link"}
        </Button>
      </Flex>
    </Box>
  );
}

export default function ShareSession({ sessionId }: { sessionId: any }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Tooltip label="Share session">
        <IconButton
          aria-label="Share Session"
          variant="beOutline"
          onClick={onOpen}
          size="sm"
          borderRadius="50%"
          icon={<FontAwesomeIcon icon={faQrcode} size={"sm"} />}
        />
      </Tooltip>
      <Modal
        trapFocus={false}
        variant="beVariant"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Share Session</ModalHeader>
          <ModalBody>
            <ShareInfo sessionId={sessionId} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
