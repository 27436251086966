import React, { useEffect } from "react";
import {
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadphones } from "@fortawesome/free-solid-svg-icons";

export default function TextToSpeechBox({
  onRegister,
  onToggle
}: {
  onRegister: (callback: (bytes: any) => void) => void
  onToggle: (isEnabled: boolean) => void
}) {

  const [isEnabled, setIsEnabled] = React.useState(false);
  const textToSpeechQueue = React.useRef<Int16Array[]>([]);
  const audioContext = React.useRef<AudioContext>();

  const isPlaying = React.useRef(false);

  const toggleTextToSpeech = () => {
    if (!isEnabled) {
      enableAudioContext();
    } else {
      isPlaying.current = false;
      disableAudioContext();
    }
    onToggle(!isEnabled);
    setIsEnabled(previousState => !previousState);
  }

  const incomingData = (audioBytes: any) => {
    if (!audioContext.current || isPlaying.current) {
      textToSpeechQueue.current.push(audioBytes);
      return;
    }

    playAudio(audioBytes);
  }

  const playAudio = (audioBytes: any) => {
    if (!audioContext.current) {
      return;
    }

    isPlaying.current = true;

    const buffer = new Float32Array(audioBytes.length / 2);
    for (let i = 0; i < audioBytes.length; i += 2) {
      const value = audioBytes[i] + (audioBytes[i + 1] << 8);
      buffer[i / 2] = value >= 0x8000 ? -(0x10000 - value) / 0x8000 : value / 0x7FFF;
    }

    const audioBuffer = audioContext.current.createBuffer(1, buffer.length, 16000);
    audioBuffer.copyToChannel(buffer, 0);

    const source = audioContext.current.createBufferSource();
    source.buffer = audioBuffer;

    source.connect(audioContext.current.destination);
    source.loop = false;

    source.onended = () => {      
      if (textToSpeechQueue.current.length > 0) {
        const nextAudioBytes = textToSpeechQueue.current.shift();
        playAudio(nextAudioBytes);
      } else {
        isPlaying.current = false;
      }
    };

    source.start();
  }

  useEffect(() => {
    onRegister(incomingData);
    return () => {
      // Clean up audio context when component unmounts
      disableAudioContext();
    }
  }, []);

  const disableAudioContext = () => {
    if (audioContext.current) {
      audioContext.current.close();
      audioContext.current = undefined;
    }
  }

  const enableAudioContext = () => {
    audioContext.current = new AudioContext();

    const oscillator = audioContext.current.createOscillator();
    oscillator.frequency.value = 25000;

    oscillator.connect(audioContext.current.destination);
    oscillator.start();

    setTimeout(() => {
      oscillator.stop();
    }, 500);

  };

  return (
    <>
      <Tooltip label="Enable/Disable text to speech">
        <IconButton
          aria-label="Text to speech"
          variant="beOutline"
          onClick={toggleTextToSpeech}
          size="sm"
          backgroundColor={isEnabled ? 'primaryBlue' : '#ffffff'}
          borderRadius="50%"
          icon={<FontAwesomeIcon icon={faHeadphones} size={"sm"} color={!isEnabled ? 'primaryBlue' : '#ffffff'} />}
        />
      </Tooltip>
    </>
  );
}
