import { menuTheme } from "./components/beMenuTheme";
import { badgeTheme } from "./components/beBadgeTheme";
import { extendTheme } from "@chakra-ui/react";
import { alertTheme } from "./components/beAlertTheme";
import { beAvatarTheme } from "./components/beAvatarTheme";
import { inputTheme } from "./components/beInputTheme";
import { buttonTheme } from "./components/beButtonTheme";
import { MultiSelectTheme } from "chakra-multiselect";
import { textTheme } from "./components/beTextTheme";
import { modalTheme } from "./components/beModalTheme";
import {
  CLR_PRIMARY_BG,
  CLR_SECONDARY_BG,
  CLR_SUCCESS_BG,
  CLR_WARNING_BG,
  CLR_DANGER_BG,
  CLR_TEXT_DESCRIPTION,
  CLR_MUTED_PRIMARY,
} from "./colors";

const theme = extendTheme({
  initialColorMode: 'dark',
  useSystemColorMode: false,
  colors: {
    primaryBlue: CLR_PRIMARY_BG,
    secondaryBlue: CLR_SECONDARY_BG,
    primaryBlueText: CLR_PRIMARY_BG,
    primaryBlueTextDark: 'white',
    danger: CLR_DANGER_BG,
    success: CLR_SUCCESS_BG,
    warning: CLR_WARNING_BG,
    textDescription: CLR_TEXT_DESCRIPTION,
    mutedBlue: '#D0E0FC',    
    mutedGreen: '#CDE8E5',		
  },
  fonts: {
		heading: `'HelveticaNow', sans-serif`,
		body: `'HelveticaNow', sans-serif`,
	},
  components: {
    Menu: menuTheme,
    Badge: badgeTheme,
    Alert: alertTheme,
    Avatar: beAvatarTheme,
    Input: inputTheme,
    Button: buttonTheme,
    Text: textTheme,
    MultiSelect: MultiSelectTheme,
    Modal: modalTheme,
  },
  styles: {
    global: {
      "html, body": {
        "overflow-x": "hidden",
      },
    },
  },
});

export default theme;
