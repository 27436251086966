import {GET_ACTIVE_SESSIONS, SET_ACTIVE_SESSIONS} from '../Constants/dashboard';

export function getActiveSessions() {
  return { type: GET_ACTIVE_SESSIONS };
}

export function setActiveSessions(payload: any) {
  return {type: SET_ACTIVE_SESSIONS, payload};
}



