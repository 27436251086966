import { useEffect, useState } from "react";
import { Box, Flex, Text, Spinner, Select, Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import {
  participantsColors,
  loadMessages,
} from "../../../Core/Hooks/useSessionJoined";
import { useParams } from "react-router-dom";
import Messages from "../../SessionJoined/Components/Messages/Web";
import BonfireAPI from "../../../Core/Data/Http";
import { GET_SESSION } from "../../../Models/Sessions/urls";
import { GET_LANGUAGES } from "../../../Models/Languages/urls";
import LanguagesModel from "../../../Models/Languages";
import SessionsModel from "../../../Models/Sessions";
import { ChevronDownIcon } from "@chakra-ui/icons";
import T from "../../../Core/Translations";
import SummaryAnnotations from "../Components/SummaryAnnotations/Web";
import CorrectedAnnotations from "../Components/CorrectedAnnotations/Web";

import { CLR_PANEL_BG } from "../../../../Lib/Theme/Light/colors";

const AnnotationsWebViewer = () => {
  const [messages, setMessages] = useState<[]>([]);
  const [language, setLanguage] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [session, setSession] = useState<SessionsModel>();
  const [languagesList, setLanguagesList] = useState<LanguagesModel[]>([]);

  const { sessionId } = useParams();

  useEffect(() => {
    BonfireAPI.request(GET_SESSION, SessionsModel, { sessionId }).then(
      (session) => {
        setSession(session as SessionsModel);
        BonfireAPI.requestArray(GET_LANGUAGES, LanguagesModel).then(
          (languages) => {
            setLanguagesList(languages as LanguagesModel[]);

            const sessionData = session as SessionsModel;
            const speakingLang = sessionData.speakingLanguage[0].split('-')[0];

            loadMessages(
              !language ? speakingLang : language,
              sessionId as string
            ).then((messages: any) => {
              setMessages(messages);

              if (!language) {
                if (sessionData.speakingLanguage.length > 1) {
                  setLanguage("floor");
                } else {
                  setLanguage(speakingLang);
                }
              }
              setLoading(false);
            });
          }
        );
      }
    );
  }, [language]);

  if (loading) {
    return (
      <Flex
        minH="100vh"
        minW="100vw"
        backgroundColor={CLR_PANEL_BG}
        justifyContent="center"
        alignItems="center"
      >
        <Spinner />
      </Flex>
    );
  }

  const speakingLang: string[] = session ? session.speakingLanguage.map((lang: any) => lang.split("-")[0]) : [];

  const getSessionLanguages = (): any[] => {

    if (!session) {
      return [];
    }

    const sessionLanguages = languagesList.filter((lang: any) =>
      [...speakingLang, ...session?.languages].includes(lang.code),
    );

    if (speakingLang.length > 1) {
      sessionLanguages.push(new LanguagesModel({ code: "floor", name: "Original" }));
    }
    return sessionLanguages;
  };

  const sessionLanguages = getSessionLanguages();


  return (
    <Box minH="100vh" backgroundColor={CLR_PANEL_BG}>
      {!!sessionLanguages && sessionLanguages?.length > 1 && (
        <Box zIndex={10} position="sticky" top="0px">
          <Select
            shadow="0px 4px 4px 0px rgba(74, 74, 74, 0.04)"
            bg="white"
            zIndex={1000}
            icon={<ChevronDownIcon />}
            onChange={(e) => setLanguage(e.target.value)}
            value={language}
          >
            {sessionLanguages.map((sessionLanguage) => (
              <option value={sessionLanguage.code}>
                {sessionLanguage.name}
              </option>
            ))}
          </Select>
        </Box>
      )}
      <Flex flexDir="column" justifyContent="space-between" zIndex={1}>
        <Tabs mb="23px">
          <TabList borderBottomWidth="1px">
            <Tab fontWeight={400}>
              {T.get('Original')}
            </Tab>
            {/* <Tab fontWeight={400} >
              {T.get('Corrected')}
            </Tab> */}
            <Tab fontWeight={400} >
              {T.get('Summary')}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {messages.length === 0 && (
                <Text variant="beTextDescription">
                  There was nothing in this session
                </Text>
              )}
              <Messages
                finalSentences={messages}
                loadingMessages={loading}
                temporarySentence={null}
                participantsColors={participantsColors}
              />
            </TabPanel>
            {/* <TabPanel>
              <CorrectedAnnotations
                sessionId={session?.sessionId}
                langCode={language}
                conversation={messages}
                participantsColors={participantsColors}
              />
            </TabPanel> */}
            <TabPanel>
            {session?.autoClean && (
                <Text variant="beTextDescription">
                  There was nothing in this session
                </Text>
              )}
              <SummaryAnnotations
                sessionId={session?.sessionId}
                langCode={language}
                sessionLanguages={sessionLanguages}
                autoClean={session?.autoClean || false}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Box>
  );
};

export default AnnotationsWebViewer;
