import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const beSubTextHeading = defineStyle({
    fontWeight: "600",
    fontSize: "18px",
    _light: {
        color: "primaryBlueText",
    },
    _dark: {
        color: "primaryBlueTextDark"
    }
})

const beTextHeading = defineStyle({
    fontWeight: "600",
    fontSize: "32px",
    _light: {
        color: "primaryBlueText",
    },
    _dark: {
        color: "primaryBlueTextDark"
    }
})

const beTextDescription = defineStyle({
    fontWeight: "600",
    fontSize: "14px",
    _light: {
        color: "textDescription",
    },
    _dark: {
        color: "primaryBlueTextDark"
    }
})

const beTextDescriptionFixed = defineStyle({
    fontWeight: "600",
    fontSize: "14px",
    color: "textDescription"
})

export const textTheme = defineStyleConfig({
    variants: { beSubTextHeading, beTextDescription, beTextDescriptionFixed, beTextHeading }
})