import { Box, Progress, Text } from "@chakra-ui/react";
import { useUserSubscription } from "../../../../../Core/Hooks/useDashboard";
import T from "../../../../../Core/Translations";

const PlatformUsageBox = (props: {
  session: any;
  sessionLiveMinutesUsed: number;
}) => {
  const { userSubscription } = useUserSubscription();

  const sessionHasEnded = props.session?.session?.session?.status === "stopped";

  const minutesUsed = props.sessionLiveMinutesUsed ? props.sessionLiveMinutesUsed : userSubscription?.minutesUsed || 0;

  const sessionIsFreeTrial =
    props.session?.session?.session?.plan?.name === "freeTrial";

  if (!sessionIsFreeTrial) {
    return null;
  }

  return (
    <Box
      bg="mutedBlue"
      w="full"
      p="10px"
      pl="23px"
      pr="23px"
      borderTopRadius="4px"
    >
      <Text variant="beTextDescriptionFixed">You are on the free trial</Text>
      <Progress
        aria-label={T.get("Usage minutes used")}
        borderRadius="4px"
        max={userSubscription?.trial?.freeMinutes}
        bg="white"
        value={minutesUsed}
      />
      <Text textAlign="right" variant="beTextDescriptionFixed">
        {minutesUsed} of {userSubscription?.trial?.freeMinutes} minutes used
      </Text>
    </Box>
  );
};

export default PlatformUsageBox;
