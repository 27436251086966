import {combineReducers} from 'redux';
import GeneralReducer from './general';
import DashboardReducer from './dashboard';
import UserReducer from './user';
import SessionReducer from './session';
import AnnotationsReducer from "./annotations";
import MediaReducer from "./media";

const rootReducer = combineReducers({
  dashboard: DashboardReducer,
  user: UserReducer,
  session: SessionReducer,
  annotations: AnnotationsReducer,
  general: GeneralReducer,
  media: MediaReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
