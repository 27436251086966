import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { CLR_PANEL_BG } from '../colors';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
	header: {
		_light: {
			bg: CLR_PANEL_BG,
		},
		_dark: {
			bg: 'gray.700',
		},
		padding: '16px 24px',
		borderTopRadius: '4px',
	},
	body: {
		padding: '32px 24px',
	},
	dialog: {
		_light: {
			bg: CLR_PANEL_BG,
		},
		_dark: {
			bg: 'gray.700',
		}		
	},
});

export const modalTheme = defineMultiStyleConfig({ baseStyle });
