import { ShareInfo } from "../../SessionJoined/Components/ShareSession/Web";
import FormWrapper from "../../../Components/FormWrapper/Web";
import { useParams } from "react-router-dom";
import { Box, Button } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import config from "../../../Config";

const ShareSessionPage = () => {
  const { sessionId } = useParams();

  const downloadQrImage = () => {
    fetch(`${config.api.url}/sessions/qr/${sessionId}`) // Replace with the URL of your image
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `session-${sessionId}.jpg`; // Specify the filename for the downloaded image
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading image:", error));
  };

  return (
    <FormWrapper>
      <Box position="relative">
        <Button
          position="absolute"
          right="20px"
          top="20px"
          p="2"
          border="2px solid"
          bg="white"
          borderColor="primaryBlue"
          borderRadius="50%"
          onClick={downloadQrImage}
        >
          <DownloadIcon color="primaryBlue" />
        </Button>
        <ShareInfo sessionId={sessionId} />
      </Box>
    </FormWrapper>
  );
};

export default ShareSessionPage;
