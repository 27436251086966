import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
	inputAnatomy.keys,
);

const beVariant = definePartsStyle({
	field: {
		_light: {
			bg: 'white',
		},
		_dark: {
			bg: '#000',
			color: 'white'
		},
		borderRadius: '4px',
		light: {
			border: '1px solid #DDDDDD',
		}
	},
});

export const inputTheme = defineMultiStyleConfig({
	variants: { beVariant },
});
