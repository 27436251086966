import { Store } from "redux";

class StoreRegistry {

    static instance: StoreRegistry;
    store!: Store

    setStore(store: Store) {
        this.store = store
    }

    static getStore() {
        return StoreRegistry.shared().store
    }

    static shared() {

        if (!StoreRegistry.instance) {
            StoreRegistry.instance = new StoreRegistry()
        }

        return StoreRegistry.instance

    }

}

export default StoreRegistry
