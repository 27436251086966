// @ts-nocheck
import ModelAbstract from '../ModelAbstract';

export type SessionEvent = {
	eventName: string;
	timeStamp: Date;
}

export default class SessionsModel extends ModelAbstract {
	name: string;
	description: string;
	type: string;
	sessionId: string;
	shortSessionId: string;
	events: SessionEvent[];
	eventId: string;
	userId: string;
	eventType: string;
	status: string;
	plan: any;
	speakingLanguage: string[];
	languages: string[];
	dictionaryId: string;
	autoClean: boolean;

	constructor(data: any) {
		super();
		this.name = data.name;
		this.description = (data.description || "").trim();
		this.type = data.type;
		this.sessionId = data.sessionId;
		this.shortSessionId = data.shortSessionId;
		this.eventId = data.eventId;
		this.userId = data.userId;
		this.events = data.events;
		this.eventType = data.eventType;
		this.status = data.status;
		this.plan = data.plan
		this.speakingLanguage = !Array.isArray(data.speakingLanguage) ? [data.speakingLanguage] : data.speakingLanguage;
		this.languages = data.languages
		this.dictionaryId = data.dictionaryIds ? data.dictionaryIds[0] : undefined
		this.autoClean = data.autoClean;

		this.mapData();
	}

	mapData(): void {

	}

	validate(data: any): string {

		if (data.sessionId) {
			return null;
		}

		if (data.error) {
			return data.error;
		}

		return "Unexpected error";

	}

	static handleError(error: Error): void {
		console.log(error);
	}
}
