//@ts-nocheck
import { type AxiosInstance } from "axios";
import config from "../../../Config";
import type ModelAbstract from "../../../Models/ModelAbstract";
import { UrlConstant } from "../../../Core/Interfaces/interfaces";

import HttpModule from "./httpModule";

class BonfireAPI {
  private client: AxiosInstance;
  static instance: BonfireAPI;

  constructor() {
    this.client = HttpModule.createAxiosClient(config.api.url);
    return;
  }

  async init() {
    this.client = await HttpModule.createAxiosClient(config.api.url);
  }

  async logout() {
    try {
      await this.client.get(`/users/logout`, {});    
    } catch (error) {
      console.log("Error logging out", error);
    }

    const isLoggedOut = await HttpModule.logout();

    if (isLoggedOut) {
      BonfireAPI.instance.init();
    }
  }

  requestMethod(urlConstant: UrlConstant, payload?: any) {
    const { path, method } = urlConstant;

    const methods: any = {
      GET: this.get.bind(this),
      POST: this.post.bind(this),
      DELETE: this.delete.bind(this),

      PATCH: this.patch.bind(this),
      PUT: this.put.bind(this),
    };

    return methods[method](path, payload);
  }

  async checkAndRenewToken() {
    const isRenewed = await HttpModule.checkAndRenewToken();

    if (isRenewed) {
      await this.init();
    }
  }

  async requestArray<T extends ModelAbstract>(
    urlConstant: UrlConstant,
    // eslint-disable-next-line no-unused-vars
    model: new (data: any) => T,
    payload?: any
  ): Promise<T[] | Error> {
    try {
      await this.checkAndRenewToken();

      const apiData = await this.requestMethod(urlConstant, payload);

      if (!apiData || !Array.isArray(apiData)) {
        throw Error("token_not_valid");
      }

      return apiData.map((item: any) => {
        const constructedModel = new model(item);
        const validation = constructedModel.validate(item);
        if (validation) {
          throw validation;
        }
        return constructedModel;
      });
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }

  async request<T extends ModelAbstract>(
    urlConstant: UrlConstant,
    // eslint-disable-next-line no-unused-vars
    model: new (data: any) => T,
    payload?: any
  ): Promise<T | Error> {
    try {
      await this.checkAndRenewToken();

      const apiData = await this.requestMethod(urlConstant, payload);
      const constructedModel = new model(apiData);
      const validation = constructedModel.validate(apiData);

      if (validation) {
        throw validation;
      }

      return constructedModel;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }

  private buildPathWithVars(path: string, data?: any): string {
    if (!data) {
      return path;
    }

    let pathWithVars = path;

    Object.keys(data).map((key) => {
      if (!pathWithVars.includes(`:${key}`)) {
        return false;
      }
      pathWithVars = pathWithVars.replace(`:${key}`, data[key]);
      delete data[key];
      return true;
    });

    return pathWithVars;
  }

  async get(path: string, data?: any) {
    try {
      const pathWithVars = this.buildPathWithVars(path, data);
      const response = await this.client.get(pathWithVars);

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return error;
    }
  }

  async post(path: string, payload: any) {
    try {
      const pathWithVars = this.buildPathWithVars(path, payload);
      const response = await this.client.post(pathWithVars, payload);

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return error;
    }
  }

  async put(path: string, payload: any) {
    try {
      const pathWithVars = this.buildPathWithVars(path, payload);
      const response = await this.client.put(pathWithVars, payload);

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return error;
    }
  }

  async patch(path: string, payload: any) {
    try {
      const pathWithVars = this.buildPathWithVars(path, payload);
      const response = await this.client.patch(pathWithVars, { data: payload });

      if (response.statusText === "OK") {
        return response.data;
      }
    } catch (error) {
      return error;
    }
  }

  async delete(path: string, payload: any) {
    try {
      const pathWithVars = this.buildPathWithVars(path, payload);
      const response = await this.client.delete(pathWithVars, payload);

      if (response.statusText === "OK") {
        return response.data;
      }
    } catch (error) {
      return error;
    }
  }

  static i(): BonfireAPI {
    if (!BonfireAPI.instance) {
      BonfireAPI.instance = new BonfireAPI();
      BonfireAPI.instance.init();
    }
    return BonfireAPI.instance;
  }
}

export default BonfireAPI.i();
