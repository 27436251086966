import { useState, useEffect } from 'react';
import { Input, Button, Flex, Text, Box, Spinner } from '@chakra-ui/react';
import BonfireAPI from '../../../../../Core/Data/Http';
import { GENERATE_SUMMARY, GET_SUMMARY } from '../../../../../Models/TranscriptionMessage/urls';
import { CLR_AUDIO_INDICATOR } from '../../../../../../Lib/Theme/Light/colors';

const SummaryAnnotations = ({
    sessionId,
    langCode,
    autoClean,
    sessionLanguages,
}: {
    sessionId: string | undefined;
    langCode: string | undefined;
    sessionLanguages: any[];
    autoClean: boolean;
}) => {
    const [transcript, setTranscript] = useState('Can you summarize the transcript?');
    const [isLoading, setIsLoading] = useState(false);
    const [summary, setSummary] = useState<string>('');

    const handleGenerateSummary = async () => {
        setIsLoading(true);
        BonfireAPI.post(GENERATE_SUMMARY.path, { transcript, sessionId, langCode,sessionLanguages }).then((response) => {
            console.log(response);
            setSummary(response.summary);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const getSummaryIfExist = async () => {
        await BonfireAPI.get(GET_SUMMARY.path + `/${sessionId}/${langCode}`).then((response) => {
            if (response) {
                setSummary(response.summary);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getSummaryIfExist();
    }, [langCode]);

    return (
        <>
            <Flex justifyContent={"space-between"}>
                {/* <Input
                    bgColor={"#fff"}
                    width={"50%"}
                    defaultValue="Can you summarize the transcript?"
                    onChange={(e) => setTranscript(e.target.value)}
                /> */}
                <Button
                    mt="10px"
                    borderRadius={"4px"}
                    _hover={{ bg: "secondaryBlue" }}
                    background="secondaryBlue"
                    size="md"
                    onClick={handleGenerateSummary}
                    isLoading={isLoading}
                    isDisabled={summary.length > 0 || autoClean}
                >
                    <Text color="#fff">Generate</Text>
                </Button>
            </Flex>
            {isLoading ?
                <Flex
                    mt="40px"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='lg'
                    />
                </Flex>
                :
                <Box
                    fontWeight="400"
                    fontSize="14px"
                    boxDecorationBreak="clone"
                    borderRadius="4px"
                    padding="6px"
                    mt="10px"
                    w="fit-content"
                    lineHeight="2"
                    backgroundColor={CLR_AUDIO_INDICATOR}
                    position="relative"
                    border="2px solid transparent"
                    display={summary ? "block" : "none"}
                >
                    <Text>{summary}</Text>
                </Box>}
        </>
    );
};

export default SummaryAnnotations;