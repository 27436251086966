// @ts-nocheck
import ModelAbstract from '../ModelAbstract';

export default class GeneralModel extends ModelAbstract {
	data: any;

	constructor(data: any) {
		super();
		this.data = data;

		this.mapData();
	}

	mapData(): void {

	}

	validate(data: any): string {
		return "";
	}

	static handleError(error: Error): void {
		console.log(error);
	}
}
