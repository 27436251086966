// @ts-nocheck
import ModelAbstract from '../ModelAbstract';
import SessionsModel from "../Sessions";

export default class SessionsAuthModel extends ModelAbstract {
	sessionId: string;
	accessToken: string;
	sessionContext: any;
	isOwner: boolean;
	session: SessionsModel;
	raw: any;
	organizationLogo: string;
	dictionaryName: string;

	constructor(data: any) {
		super();
		this.sessionId = data.session_id;
		this.accessToken = data.access_token;
		this.sessionContext = data.context;
		this.isOwner = data.isOwner || false;
		this.session = data.session ? new SessionsModel(data.session) : null;
		this.raw = data;
		this.organizationLogo = data.organizationLogo;
		this.dictionaryName = data.dictionaryName;

		this.mapData();
	}

	mapData(): void {

	}

	validate(data: any): string {

		if (data.session_id) {
			return null;
		}

		if (data.error) {
			return data.error;
		}

		return "Unexpected error";

	}

	static handleError(error: Error): void {
		console.log(error);
	}
}
