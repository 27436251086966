import { HttpMethod } from "../methods";
import { UrlConstant } from "../../Core/Interfaces/interfaces";

export const GET_ANNOTATION: UrlConstant = {
  path: "/annotations/:sessionId",
  method: HttpMethod.GET,
};
export const POST_ANNOTATION: UrlConstant = {
  path: "/annotations",
  method: HttpMethod.POST,
};
export const REVISE_MESSAGE: UrlConstant = {
  path: "/annotations/revise",
  method: HttpMethod.POST,
};
