import React, { useState } from 'react';
import { Box, Button, Center, Flex, Heading, Link } from "@chakra-ui/react";
import SessionJoinedHelpers from "../../SessionJoined/Helpers/Web";
import config from '../../../Config';
import {
faThumbsDown,
faThumbsUp,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CLR_SECONDARY_BG } from '../../../../Lib/Theme/Light/colors';

const LanguageDetection = () => {

  const [status, setStatus] = useState('idle');
  const [result, setResult] = useState('');
  const [lastId, setLastId] = useState('');

  const startGathering = () => {

    setStatus('inprogress');

    const urlParams = new URLSearchParams(window?.location?.search);
	let time = urlParams.get('time') ? parseInt(urlParams.get('time')!) : 4000;

    if (time < 1000) {
        time = 1000;
    }

    SessionJoinedHelpers.gatherSoundFromMic(time, async (audioChunks) => {

        try {
            const result = await fetch(config.aicore.url + '/language/detect', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ chunks: audioChunks, time }),
            });

            setStatus('idle');

            const json = await result.json();

            setResult(json.detected);
            setLastId(json.id);

        } catch (e) {
            setResult('');
            setStatus('idle');
        }

    });

  }

  const sendFeedback = async (feedback: string) => {

    try {
        fetch(config.aicore.url + '/language/feedback', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: lastId, feedback }),
        });

        setLastId('');

    } catch (e) {
        setResult('');
    }

  }

  const renderContent = () => {

    if (status === 'inprogress') {
        return <Flex alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
            <Heading>Please speak in your preferred language</Heading>
            <Box mt={10}>Listening for your voice...</Box>
        </Flex>
    }

    return <Flex alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
        <Heading>We will try to identify your language</Heading>
        {result && <Box mt={10}>We think you are speaking in <strong>{result.toUpperCase()}</strong></Box>}
        {lastId && <Box mt={5}>
            <FontAwesomeIcon
                fontSize={"20px"}
                color={CLR_SECONDARY_BG}
                cursor={'pointer'}
                icon={faThumbsUp}
                onClick={() => { sendFeedback('yes')}}
            />
            <FontAwesomeIcon
                style={{marginLeft: '10px'}}
                cursor={'pointer'}
                fontSize={"20px"}
                color={CLR_SECONDARY_BG}
                icon={faThumbsDown}
                onClick={() => { sendFeedback('no')}}
            />
            </Box>}
        <Button mt={10} onClick={startGathering} variant={'beSecondary'}>{result ? 'Try again' : 'Click to start'}</Button>
    </Flex>        

  }

  return <Center
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      h='calc(100vh)'>

      {renderContent()}
  </Center>;
};

export default LanguageDetection;
