import {createStore, applyMiddleware} from 'redux';
import rootReducer from './Reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './Sagas';
import storeRegistry from './storeRegistry';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);

storeRegistry.shared().setStore(store);

export default store;
