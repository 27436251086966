import {
    Box,
    Text,
    Center,
    Flex,
    Divider,
    Button,
    Link,
    useColorMode,
    Image
  } from "@chakra-ui/react";
  import T from "../../../../Core/Translations";
  import { EmailIcon } from "@chakra-ui/icons";
  import config from "../../../../Config";
  import { CLR_PANEL_BG, CLR_PANEL_BG_DARK } from "../../../../../Lib/Theme/Light/colors";
  
  export default function DashboardScreen() {
  
    const { colorMode } = useColorMode();
  
    return (
      <Center
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        mt={[0, 0, 20]}
      >
        <Flex
          w={["full", "full", "800px"]}
          h={["calc(100vh)", "calc(100vh)", "full"]}
          bgColor={colorMode === 'light' ? CLR_PANEL_BG : CLR_PANEL_BG_DARK}
          flexDirection="column"
          padding={[8, 8, 10]}
          borderRadius={8}
          borderWidth={[0, 0, 1]}
          overflow="auto"
        >
          <Text variant="beTextHeading" fontSize="24px" textAlign="center">
            {T.get("Boostlingo AI Pro")}
          </Text>
          <Divider mt="23px" mb="23px" />
          <Flex alignItems="center" gap="12px" mt="12px" mb="24px" textAlign={'center'}>           
            <Text>
              {T.get(
                "The Boostlingo AI Pro app is accessible exclusively during ongoing Teams events, activated by pressing the 'Join' button."
              )}
            </Text>
          </Flex>
          <Divider mb={5} />
          <Center>
            <Image src={'/assets/images/teams-app-topbar.png'} alt="Dashboard" width={200} />
          </Center>
         
          <Text>
            <Text display="block" variant="beTextDescription" textAlign={'center'}>
              {T.get(
                "Upon joining the meeting, simply navigate to the top bar and select the Boostlingo AI Pro app to seamlessly access its content via the side panel interface."
              )}
            </Text>
          </Text>
        </Flex>
      </Center>
    );
  }
  