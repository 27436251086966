import UserModel from "../../../../Models/Users";
import { SET_USER_SUBSCRIPTION, SET_ACTIVE_USER, SET_USER_ORGANIZATIONS } from "../Constants/user";
import SubscriptionsModel from "../../../../Models/Subscriptions";
import OrganizationModel from "../../../../Models/OrganizationModel";

export default function UserReducer(
  state: {
    activeUser: UserModel | {};
    subscription: SubscriptionsModel | null;
    organizations: OrganizationModel[];
  } = {
    activeUser: {},
    subscription: null,
    organizations: [],
  },
  action: { type: string; payload: any }
) {
  if (action.type === SET_ACTIVE_USER) {
    const newState = Object.assign({}, state);
    newState.activeUser = action.payload;
    return newState;
  } else if (action.type === SET_USER_SUBSCRIPTION) {
    const newState = Object.assign({}, state);
    newState.subscription = action.payload;
    return newState;
  } else if (action.type === SET_USER_ORGANIZATIONS) {
    const newState = Object.assign({}, state);
    newState.organizations = action.payload;
    return newState;
  }

  return state;
}
