// @ts-nocheck
import ModelAbstract from '../ModelAbstract';

export default class TranscriptionMessageModel extends ModelAbstract {
	langCode: string;
	message: string;
	participant: string;
	isFinal: boolean;
	revision: any;
	date: Date;
	id: string;
	explain: {explain: string, word: string}[];
	processedData: {word: string, type: string, explain?: string}[];

	constructor(data: any) {
		super();

		this.langCode = data.messages[0].langCode;
		this.message = data.messages[0].message;
		this.participant = data.messages[0].participant;
		this.date = data.messages[0].date;
		this.revision = data.messages[0].revision || null;
		this.id = data.messages[0].id;
		this.processedData = data.messages[0].processedData || [];
		this.isFinal = !!data.messages[0].isFinal;

		this.formatExplain();

		this.mapData();
	}

	mapData(): void {

	}

	formatExplain(): void {
		this.explain = [];

		this.processedData.forEach((data: any) => {
			if (data.type === "explain") {
				this.explain.push({explain: data.explain, word: data.word});
			}
		});
	}

	validate(data: any): string {

		if (data.messages) {
			return null;
		}

		if (data.error) {
			return data.error;
		}

		return "Unexpected error";

	}

	static handleError(error: Error): void {
		console.log(error);
	}
}
