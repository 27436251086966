import React from "react";
import { Button, Flex, Text, useClipboard } from "@chakra-ui/react";
import SessionFormInfo from "../../../Components/SessionFormInfo/Web";
import FormWrapper from "../../../Components/FormWrapper/Web";
import T from "../../../Core/Translations";
import { LinkIcon } from "@chakra-ui/icons";
import config from "../../../Config";
import { useParams } from "react-router-dom";

const SessionDetails = () => {
  const { sessionId } = useParams();
  const sessionJoinedLink = `${config.webapp.url}/session-joined/${sessionId}`;
  const { hasCopied, onCopy } = useClipboard(sessionJoinedLink);

  return (
    <FormWrapper>
      <Flex alignItems="center" mt="23px" justifyContent="space-between">
        <Text variant="beTextHeading">{T.get("Session Details")}</Text>
        <Button
          size="sm"
          leftIcon={<LinkIcon />}
          variant="unstyled"
          onClick={onCopy}
        >
          {hasCopied ? T.get("Copied!") : T.get("Copy link")}
        </Button>
      </Flex>
      <Text variant="beTextDescription" mb="23px">
        {T.get(
          `View details of your session and update the session information.`
        )}
      </Text>
      <SessionFormInfo
        submitButtonText="Update session details"
        loadingSubmit={false}
        handleSubmit={() => console.log("submitted")}
      />
    </FormWrapper>
  );
};

export default SessionDetails;
