import ModelAbstract from "../ModelAbstract";

export default class AnnotationsModel extends ModelAbstract {
  sessionId: string;
  userId: string;
  type: string;
  messages: string[];
  attachment: string;
  annotation: string;

  constructor(data: any) {
    super();
    this.sessionId = data.sessionId;
    this.userId = data.userId;
    this.type = data.type;
    this.messages = data.messages;
    this.attachment = data.attachment;
    this.annotation = data.annotation;

    this.mapData();
  }

  mapData(): void {}

  validate(data: any): string {
    if (data.messages) {
      return "";
    }

    if (data.error) {
      return data.error;
    }

    return "Unexpected error";
  }

  static handleError(error: Error): void {
    console.log(error);
  }
}
