import {SET_ACTIVE_SESSIONS} from '../Constants/dashboard';

export default function DashboardReducer(
  state = {
    activeSessions: [],
  },
  action: any,
) {
  if (action.type == SET_ACTIVE_SESSIONS) {
    var newState = Object.assign({}, state);
    newState.activeSessions = action.payload;

    return newState;
  }

  return state;
}
