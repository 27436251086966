import React, { useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  Text,
  Button,
} from "@chakra-ui/react";
import BonfireAPI from "../../../../../../AppBase/Core/Data/Http";
import GeneralModel from "../../../../../../AppBase/Models/General";
import { RESEND_ACTIVATION_CODE } from "../../../../../Models/Users/urls";

export default function ActivationRequired({ userData } : { userData: any }) {
  
  const [message, setMessage] = useState('Please check your email for an activation code.');

	const onClose = () => {
		// TODO
	};
  
  const resend = async () => {
		setMessage('sending ...');
		const errorMsg = "Can't resent activation code. Please try back later or contact support.";
		try {
      const response = await BonfireAPI.request(RESEND_ACTIVATION_CODE, GeneralModel, {
        email: userData.email,
      });
			if (response instanceof GeneralModel) {
        if (response.data.resendActivationCode) {
          setMessage('Activation code has been resent, check your email.');
        } else {
          setMessage(errorMsg);
        }
			} else {
				setMessage(errorMsg);
			}
		} catch (exc) {
			setMessage(errorMsg);
		}
	};

  return (
    <Modal isCentered isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center">
          <Text variant="beTextHeading" fontSize="18px">
            Activate your account
          </Text>
        </ModalHeader>
        <ModalBody gap="23px" display="flex" flexDir="column">
          <Text>{message}</Text>
          <Button variant="bePrimary" onClick={resend}>
            Resend activation code
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
