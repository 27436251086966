import {APP_OPENED, SESSION_INFORMATION_OPENED} from '../Constants/general';

export default function GeneralReducer(
  state = {
    isAppWasOpened: false,
    isSessionInformationOpened: false,
  },
  action: {type: string; payload: any}
) {
  if (action.type === APP_OPENED) {
    const newState = Object.assign({}, state);
    newState.isAppWasOpened = action.payload;
    return newState;
  } else if (action.type === SESSION_INFORMATION_OPENED) {
    const newState = Object.assign({}, state);
    newState.isSessionInformationOpened = action.payload;
    return newState;
  }

  return state;
}
